import {FETCH_ALL} from "@/services/additional-payment/payment-type";

const state = {
    types: [],
    loading: false,
};

export const MUTATIONS = {
    SET_TYPES: 'SET_TYPES',
    SET_LOADING: 'SET_LOADING',
};

export const GETTERS = {
    TYPES: 'TYPES',
    LOADING: 'LOADING',
};

export const ACTIONS = {
    FETCH_ALL: 'FETCH_ALL',
};

export const MODULE_NAME = 'TYPES';

export default {
    namespaced: true,
    state: () => state,
    getters: {
        [GETTERS.TYPES]: ({ types }) => types,
        [GETTERS.LOADING]: ({ loading }) => loading,
    },
    mutations: {
        [MUTATIONS.SET_TYPES](state, { payload }) {
            state.types = payload;
        },
        [MUTATIONS.SET_LOADING](state, { payload }) {
            state.loading = payload;
        },
    },
    actions: {
        async [ACTIONS.FETCH_ALL]({ commit }) {
            commit({ type: MUTATIONS.SET_LOADING, payload: true });

            const data = await FETCH_ALL();

            commit({ type: MUTATIONS.SET_TYPES, payload: data });
            commit({ type: MUTATIONS.SET_LOADING, payload: false });
        }
    },
};