<template>
  <div class="modal-body">
    <slot name="default"/>
  </div>
</template>

<script>
export default {
  name: 'ModalBody',
}
</script>

<style scoped>

</style>
