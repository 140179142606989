import LoanState, { MODULE_NAME as LOAN_STATE_MODULE_NAME } from '@store/modules/application/modules/LoanState';

const state = () => ({
  application: {},
});

export const MUTATIONS = {
  SET_APPLICATION: 'SET_APPLICATION',
};

export const GETTERS = {
  APPLICATION: 'APPLICATION',
  APPLICATION_ID: 'APPLICATION_ID',
  LOAN_NUMBER: 'LOAN_NUMBER',
};

export const ACTIONS = {};

export const MODULE_NAME = 'APPLICATION';

export default {
  namespaced: true,
  state,
  modules: {
    [LOAN_STATE_MODULE_NAME]: LoanState,
  },
  getters: {
    [GETTERS.APPLICATION]: ({application}) => application,
    [GETTERS.APPLICATION_ID]: ({application}) => application.id,
    [GETTERS.LOAN_NUMBER]: ({application}) => application.loan_number,
  },
  mutations: {
    [MUTATIONS.SET_APPLICATION](state, {payload}) {
      state.application = payload;
    },
  },
};
