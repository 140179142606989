<template>
  <div :class="formErrorClass(name)" class="form-group">
    <label v-if="label" ref="label" :for="id" class="col-form-label" v-text="label"/>
    <slot v-bind="{ id }"/>
    <ErrorText v-for="_name in names" :key="_name" :errorContainer="errorContainer" :name="_name"/>
  </div>
</template>

<script>
import ErrorText from '@modules/shared/components/form/ErrorText';
import ServerErrorMixin from '@modules/shared/mixins/ServerErrorMixin';
import {Str} from '@/utilities/random';

const isInputElement = (el) => {
  if (!(el instanceof HTMLElement)) {
    return false;
  }

  if (
    el instanceof HTMLInputElement
    || el instanceof HTMLSelectElement
    || el instanceof HTMLTextAreaElement
    || el.isContentEditable) {
    return true;
  }
};

export default {
  name: 'FormGroup',
  mixins: [ServerErrorMixin],
  components: {ErrorText},
  props: {
    flex: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      required: false,
      default: () => [],
    },
    errorContainer: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    names() {
      if (Array.isArray(this.name)) {
        return this.name;
      }

      return [this.name];
    },
    id: () => Str.random({length: 6, unique: true}),
  },
  watch: {
    errorContainer: {
      handler(value) {
        if (value) {
          this.registerErrorContainer(value);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    const nextElement = this.$refs.label?.nextElementSibling ?? null;

    if (this.label && isInputElement(nextElement)) {
      nextElement.id = this.id;
    }
  },
};
</script>

<style>
.form-group.flex {
  display: flex;
  flex-direction: column;
}

.has-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}
</style>
