import request from '@services/request';
import {debug} from '@/utils';

export const fetchDecisionTags = async () => {
  return await request.get(`/decision-tag-types`);
};

const state = () => ({
  types: {
    approval: [],
    rejection: [],
    withdraw: [],
  },
  meta: {
    initialized: false,
    loading: false,
  },
});

export const MUTATIONS = {
  SET_TYPES: 'SET_TYPES',
  SET_META: 'SET_META',
};

export const GETTERS = {
  APPROVAL_TYPES: 'APPROVAL_TYPES',
  REJECTION_TYPES: 'REJECTION_TYPES',
  WITHDRAW_TYPES: 'WITHDRAW_TYPES',
  META: 'META',
};

export const ACTIONS = {
  FETCH_TYPES: 'FETCH_TYPES',
  INIT: 'INIT',
};

export const MODULE_NAME = 'APPROVAL_REJECTION_TYPES';

/** For internal use only */
const setMeta = (payload) => ({type: MUTATIONS.SET_META, payload});
const setTypes = (payload) => ({type: MUTATIONS.SET_TYPES, payload});

export default {
  namespaced: true,
  state,
  getters: {
    [GETTERS.APPROVAL_TYPES]: ({types: {approval}}) => Object.fromEntries(approval.map(({ id, name}) => [id, name])),
    [GETTERS.REJECTION_TYPES]: ({types: {rejection}}) => Object.fromEntries(rejection.map(({ id, name}) => [id, name])),
    [GETTERS.WITHDRAW_TYPES]: ({types: {withdraw}}) => Object.fromEntries(withdraw.map(({ id, name}) => [id, name])),
    [GETTERS.META]: ({meta}) => meta,
  },
  mutations: {
    [MUTATIONS.SET_TYPES](state, {payload: {approval, rejection, withdraw}}) {
      state.types = {approval, rejection, withdraw};
    },
    [MUTATIONS.SET_META](state, {payload}) {
      state.meta = {...state.meta, ...payload};
    },
  },
  actions: {
    async [ACTIONS.FETCH_TYPES]({commit}) {

      try {
        commit(setMeta({loading: true}));

        const {data: { types }} = await fetchDecisionTags();

        commit(setTypes(types));
      } catch (e) {
        debug(e);
      } finally {
        commit(setMeta({loading: false, initialized: true}));
      }
    },
    async [ACTIONS.INIT]({ dispatch, state }) {
      if (state.meta.initialized) {
        return;
      }

      dispatch(ACTIONS.FETCH_TYPES);
    },
  },
};
