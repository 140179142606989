import {EmploymentStatus, Frequencies} from '@modules/guest/bought-lead/constants';
import {inObject, isEmpty, isNumber, msgRequired, validateRequired} from '@modules/shared/helpers/form';

export const validateEmploymentStatus = ({employmentStatus}) => {
  if (!inObject(employmentStatus, EmploymentStatus)) {
    return 'Employment status is invalid.';
  }

  return null;
};

const validateIncome = (fieldName, value, required = true, min = 1) => {
  if (isEmpty(value) && !required) {
    return null;
  }

  if (required && isEmpty(value)) {
    return msgRequired(fieldName);
  }

  if (!isNumber(value) || Number(value) < min) {
    return `${fieldName} allows only numeric value greater than or equal to ${min}.`;
  }

  return null;
};

const validateFrequency = (fieldName, value, required = true) => {
  if (required && isEmpty(value)) {
    return msgRequired(fieldName);
  } else if (!required) {
    return null;
  }

  if (!inObject(value, Frequencies)) {
    return `${fieldName} is invalid.`;
  }

  return null;
};

export const validate = (formFields) => {
  return {
    employer: validateRequired('Employer', formFields.employer),
    employmentStatus: validateEmploymentStatus(formFields),
    income: validateIncome('Employment income', formFields.income),
    incomeFrequency: validateFrequency('Employment income frequency', formFields.incomeFrequency),
    otherIncome: validateIncome('Other income', formFields.otherIncome, false, 0),
    otherIncomeFrequency: validateFrequency('Other income frequency', formFields.otherIncomeFrequency, !isEmpty(formFields.otherIncome)),
    houseExpense: validateIncome('House expense', formFields.houseExpense),
    houseExpenseFrequency: validateFrequency('House expense frequency', formFields.houseExpenseFrequency),
    livingExpense: validateIncome('Living expense', formFields.livingExpense),
    livingExpenseFrequency: validateFrequency('Living expense frequency', formFields.livingExpenseFrequency),
  };
};

