import {fetchAll} from "@services/solicitors/SolicitorService";

const store = {
    solicitors: {}
};

export const MUTATIONS = {
    SET_SOLICITORS: 'SET_SOLICITORS',
};

export const GETTERS = {
    SOLICITOR: 'SOLICITOR',
    SOLICITORS: 'SOLICITORS',
};

export const ACTIONS = {
    FETCH_ALL: 'FETCH_ALL',
};

export const MODULE_NAME = 'SOLICITOR';

const reducer = (accumulator, solicitor) => ({...accumulator, [solicitor.id]: solicitor});

export default {
    namespaced: true,
    state: () => store,
    getters: {
        [GETTERS.SOLICITORS]: ({solicitors}) => solicitors,
        [GETTERS.SOLICITOR]: ({solicitors}) => (id) => solicitors[id],
    },
    mutations: {
        [MUTATIONS.SET_SOLICITORS](state, {payload}) {
            state.solicitors = payload.reduce(reducer, {});
        }
    },
    actions: {
        async [ACTIONS.FETCH_ALL]({commit}) {
            try {
                const {data: {solicitors}} = await fetchAll();

                commit({
                    type: MUTATIONS.SET_SOLICITORS,
                    payload: solicitors,
                });
            } catch (e) {
                console.dir(e);
            }
        }
    }
};
