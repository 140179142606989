<template>
  <div>
    <div class="loan-purpose-picker" :style="{borderBottomWidth: selectedPurpose && selectedPurpose.warn ? 0 : '1px'}">
      <div v-for="(purposes, i) in __purposes" :key="i">
        <div v-for="(purpose, j) in purposes" :key="j" :class="{selected: purpose.description === value}"
             class="loan-purpose" @click="proxyValue = purpose.description" tabindex="0"
             ref="loanPurposes"
             @keydown.space="onProbableClick"
             @keydown.enter="onProbableClick"
        >
          <div v-if="purpose.description === value">
            <svg class="bi bi-check-circle-fill" fill="currentColor" height="16" viewBox="0 0 16 16"
                 width="16" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </div>
          <div v-else>
            <svg class="bi bi-circle" fill="currentColor" height="16" viewBox="0 0 16 16" width="16"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            </svg>
          </div>

          <span v-text="purpose.description"/>
        </div>
      </div>
    </div>

    <SmoothReflow :options="{property: ['height']}">
      <div v-if="selectedPurpose && selectedPurpose.warn"
           class="alert alert-warning m-0 rounded-0 border-0 bg-warning text-dark px-3 py-2"
           role="alert" style="font-size: 0.85rem;">
        <div class="m-0 d-flex flex-row align-items-center" style="gap: 0.5rem">
          <svg fill="currentColor" height="24" style="min-width: 1rem; max-width: 1rem; width: 1rem;"
               viewBox="0 0 16 16" width="24"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
          </svg>

          <p class="m-0" v-text="selectedPurpose.warn"></p>
        </div>
      </div>
    </SmoothReflow>
  </div>
</template>

<script>
import {LoanPurposes} from '@modules/guest/new-applicant/constants';
import {chunk} from 'lodash';
import SmoothReflow from '@modules/shared/transitions/SmoothReflow';

export default {
  name: 'LoanPurposePicker',
  components: {SmoothReflow},
  props: {
    value: {
      type: [String, null],
      required: false,
      default: null,
    },
  },
  computed: {
    __purposes: () => chunk(LoanPurposes, Math.floor(LoanPurposes.length / 2)),
    selectedPurpose() {
      return LoanPurposes.filter(p => p.description === this.value)[0] || null;
    },
    proxyValue: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: {
    onProbableClick(event) {
      const target = [...(this.$refs.loanPurposes ?? [])].find(e => e === document.activeElement);

      if (target instanceof HTMLElement) {
        event.preventDefault();
        event.stopPropagation();

        target.click();
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.loan-purpose-picker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.25rem;
  padding: 0.5rem;
  background: #f6f6f6;
  border: 1px solid #ced4da;
  font-size: 0.85rem;

  > div {
    min-width: 12.5rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .loan-purpose {
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    transition: all 150ms linear;
    border-radius: 2px;
    padding: 0.5rem;
    color: #333;

    &:hover, &:focus {
      background: #6200ee1a;
      outline: 1px solid #6200ee;
    }

    > *:first-child {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    &:not(.selected) {
      > *:first-child {
        background: #fff;
      }
    }

    &.selected {
      //color: #3e3052;
      //font-weight: 600;
      color: #fff;
      background: #6200ee;
    }
  }
}
</style>
