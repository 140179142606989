<template>
  <div class="position-relative">
    <LoadingPlaceholder :loading="showLoadingPlaceholder"/>

    <OTPVerification v-if="meta.otp"/>

    <LoanApplication v-if="!showLoadingPlaceholder && !meta.otp"/>

    <PortalTarget :multiple="false" name="steps-spinner"/>
  </div>
</template>

<script>
import store, {dispatch} from '@modules/guest/new-applicant/store/store';
import {NEW_APPLICATION} from '@store/constants';
import LoanApplication from './components/LoanApplication';
import OTPVerification from './components/OTPVerification/OTPVerification';
import SpinKit from '@components/common/SpinKit';
import Spinner from '@components/common/Spinner';
import LoadingPlaceholder from '@modules/guest/new-applicant/components/LoadingPlaceholder';

export default {
  name: 'NewApplication',
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  computed: {
    meta() {
      return store.state[NEW_APPLICATION.MODULE_NAME].meta;
    },
    showLoadingPlaceholder() {
      return this.meta.loading && (!this.meta.initialized || this.meta.otp);
    },
  },
  components: {
    LoadingPlaceholder,
    Spinner,
    SpinKit,
    LoanApplication,
    OTPVerification,
  },
  created() {
    dispatch({type: NEW_APPLICATION.ACTIONS.SET_CODE, payload: this.code});
    window.scrollTo({top: 0, behavior: 'smooth'});
  },
};
</script>

<style lang="scss">
//noinspection CssUnknownTarget
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
  background: #f1f1f1;
}

.steps-loader {
  z-index: 1111;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media screen and (max-width: 600px) {
  .form-control {
    color: #495057;
    border-color: #ced4da;
    border-width: 1px;
    border-radius: 0.25rem;
  }
}
</style>
