import {error} from '@services/AlertService';
import {HTTP_UNPROCESSABLE_ENTITY} from '@constants/ResponseStatus';
import {isAxiosError} from 'axios';
import dayjs from 'dayjs';

export const IS_PRODUCTION = (() => {
  const meta = document.head.querySelector('meta[name="app-env"]');

  return meta?.content === 'production';
})();

export const IS_DEBUG_MODE = (() => {
  const meta = document.head.querySelector('meta[name="app-dbg"]');

  return meta?.content === '1';
})();

const isServerError = (e) => {
    return e instanceof AxiosError;
};

export const debug = (...args) => {
  if (!IS_DEBUG_MODE) {
    return;
  }

  let hadNonServerErrors = false;

  args.forEach(e => {
      if (isServerError(e)) {
          return;
      }

      hadNonServerErrors = true;

      console.dir(e);
  });

  if (hadNonServerErrors) {
      console.trace('------ DEBUG TRACE ------');
  }
};

export const handleError = (e, defaultMessage = 'Unexpected error occurred') => {
  if (!isAxiosError(e) || e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
    debug(e);

    return e;
  }

  const message = e.response?.data?.message || defaultMessage;
  // noinspection JSIgnoredPromiseFromCall
  error({message});
  debug(e);
};

export const trim = (value) => {
  if (typeof value === 'string') {
    return value.trim();
  }

  return value;
};

export const lowercase = (value) => {
  if (typeof value === 'string') {
    return value.toLowerCase();
  }

  return value;
};

export const formatDate = (date, format = 'DD/MM/YYYY') => {
  return date ? dayjs(date).format(format) : null;
};

export const formatNumber = (number) => {
  const num = parseFloat(number);
  if (typeof num !== 'number' || isNaN(num) || !isFinite(num)) {
    console.error('Invalid numeric value encountered', number);
    return null;
  }

  return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lower-cased
 * @return {string}
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
export const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

