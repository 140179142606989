import {isString, validateRequired} from '@modules/shared/helpers/form';

const validatePassword = ({password, password_confirmation}) => {
  password = isString(password) ? password : '';
  password_confirmation = isString(password_confirmation) ? password_confirmation : '';

  const errors = {
    password: validateRequired('Password', password),
    password_confirmation: validateRequired('Password confirmation', password_confirmation),
  };

  if (!errors.password && password.length < 8) {
    errors.password = 'Password must be at least 8 characters long.';
    return errors;
  }

  if (password !== password_confirmation) {
    errors.password_confirmation = 'Password confirmation does not match with Password field.';
  }

  return errors;
};

export const validate = (formFields) => {
  return {
    ...validatePassword(formFields),
  };
};

