<template>
  <FormGroup class="address-input">
    <template v-slot="{id}">
      <slot name="header-text"/>
      <label :for="id" class="primary-text" v-text="name"/>
      <AddressAutocomplete :id="id" @placeChanged="onPlaceChanged"/>
      <SmoothReflow :show="meta.manualMode" class="address-container">
        <div class="row">
          <div class="col-sm-6">
            <FormGroup :error-container="errorContainer" :name="errorPrefixedKeys.unit_number" label="Unit Number">
              <input :value="proxyValue.unit_number" class="form-control" type="text"
                     @input="setPlaceField('unit_number', $event)">
            </FormGroup>
          </div>

          <div class="col-sm-6">
            <FormGroup :error-container="errorContainer" :name="errorPrefixedKeys.street_number" label="Street Number">
              <input :value="proxyValue.street_number" class="form-control"
                     type="text" @input="setPlaceField('street_number', $event)">
            </FormGroup>
          </div>

          <div class="col-sm-8">
            <FormGroup :error-container="errorContainer" :name="errorPrefixedKeys.street_name" label="Street Name">
              <input :value="proxyValue.street_name" class="form-control" type="text"
                     @input="setPlaceField('street_name', $event)">
            </FormGroup>
          </div>
          <div class="col-sm-4">
            <FormGroup :error-container="errorContainer" :name="errorPrefixedKeys.suburb" label="Suburb">
              <input :value="proxyValue.suburb" class="form-control" type="text"
                     @input="setPlaceField('suburb', $event)">
            </FormGroup>
          </div>
          <div class="col-sm-4">
            <FormGroup :error-container="errorContainer" :name="errorPrefixedKeys.state" label="State">
              <input :value="proxyValue.state" class="form-control" type="text" @input="setPlaceField('state', $event)">
            </FormGroup>
          </div>
          <div class="col-sm-4">
            <FormGroup :error-container="errorContainer" :name="errorPrefixedKeys.postcode" label="Postcode">
              <input :value="proxyValue.postcode" class="form-control" type="text"
                     @input="setPlaceField('postcode', $event)">
            </FormGroup>
          </div>
          <div class="col-sm-4">
            <FormGroup :error-container="errorContainer" :name="errorPrefixedKeys.country" label="Country">
              <input :value="proxyValue.country" class="form-control" type="text"
                     @input="setPlaceField('country', $event)">
            </FormGroup>
          </div>
        </div>
      </SmoothReflow>

      <FormGroup v-if="duration">
        <template v-slot="{id}">
          <label ref="label" :for="id" class="col-form-label primary-text">
            How long are you been in this address?
          </label>
          <div class="row address-container">
            <div class="col-md-6">
              <FormGroup :error-container="errorContainer" :name="errorPrefixedKeys.years" label="Number of years">
                <input :value="proxyValue.years" class="form-control" min="0" name="number_of_years"
                       step="1" type="number"
                       @input="setPlaceField('years', $event)"/>
              </FormGroup>
            </div>
            <div class="col-md-6">
              <FormGroup :error-container="errorContainer" :name="errorPrefixedKeys.months" label="Number of months">
                <select
                  :value="proxyValue.months"
                  class="form-control"
                  name="number_of_months"
                  @input="setPlaceField('months', $event)"
                >
                  <option :value="null" disabled>Select</option>
                  <option v-for="number in numberOfMonths" :key="number" :value="number" v-text="number"/>
                </select>
              </FormGroup>
            </div>
          </div>
        </template>
      </FormGroup>

      <slot name="extra-text"/>
    </template>
  </FormGroup>
</template>

<script>
import FormGroup from '@modules/shared/components/form/FormGroup';
import AddressAutocomplete from '@modules/shared/components/google/AddressAutocomplete';
import SmoothReflow from '@modules/shared/transitions/SmoothReflow';
import {mergeDeep} from '@modules/shared/helpers/object-fns';
import ServerErrorMixin from '@modules/shared/mixins/ServerErrorMixin';

export default {
  name: 'AddressInput',
  mixins: [ServerErrorMixin],
  components: {SmoothReflow, AddressAutocomplete, FormGroup},
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        unit_number: null,
        street_number: null,
        street_name: null,
        suburb: null,
        state: null,
        country: null,
        postcode: null,
        years: null,
        months: null,
      }),
    },
    name: {
      type: String,
      default: 'Address',
    },
    removable: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Boolean,
      default: true,
    },
    errorContainer: {
      type: String,
      default: null,
    },
    errorKeyPrefix: {
      type: String,
      default: '',
    },
  },
  computed: {
    proxyValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    errorPrefixedKeys() {
      const prefix = this.errorKeyPrefix;

      return {
        unit_number: `${prefix}unit_number`,
        street_number: `${prefix}street_number`,
        street_name: `${prefix}street_name`,
        suburb: `${prefix}suburb`,
        state: `${prefix}state`,
        country: `${prefix}country`,
        postcode: `${prefix}postcode`,
        years: `${prefix}years`,
        months: `${prefix}months`,
      };
    },
    onChange() {
      return (key, event) => {
        this.setPlaceField(key, event?.target?.value ?? null);
      };
    },
    isInvalid() {
      return Object.values(this.errorPrefixedKeys).some(key => this.validationErrors[key] ?? false);
    },
    numberOfMonths: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  },
  data() {
    return {
      meta: {
        manualMode: true,
      },
    };
  },
  watch: {
    errorContainer: {
      handler(value) {
        if (value) {
          this.registerErrorContainer(value);
        }
      },
      immediate: true,
    },
    isInvalid(isInvalid) {
      if (isInvalid) {
        this.meta.manualMode = true;
      }
    },
  },
  methods: {
    onPlaceChanged(place) {
      this.proxyValue = mergeDeep({...this.proxyValue}, place);
      this.meta.manualMode = true;
    },
    setPlaceField(key, event) {
      this.proxyValue = {...this.proxyValue, [key]: event?.target?.value ?? null};
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.address-input {
  padding: 1rem;
  background: #f1f1f1;

  .address-container {
    font-size: 0.85rem;
  }
}

</style>
