<template>
  <div class="progress-steps">
    <div
      v-for="(step, idx) in steps"
      :class="{completed: idx < currentStep, active: idx === currentStep}"
      class="progress-steps__item"
      :key="step"
    >
      <div class="step-counter"/>
      <div class="step-name" v-text="step"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepProgress',
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    currentStep: {
      type: Number,
      default: 0,
      validate(value) {
        return value >= 0 && value < this.steps.length;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-steps {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1409px) {
    flex-direction: column;
    gap: 0.5rem;

    .progress-steps__item {
      flex-direction: row;
      &:after, &:before  {
        display: none;
      }
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    color: #6200ee;
    gap: 0.5rem;

    .step-name {
      white-space: nowrap;
      font-size: 0.85rem;
      font-weight: 400;
      color: #6c757d;
      transition: font-weight 0ms linear, all 0.3s ease;
    }

    &:first-child::before {
      content: none;
    }

    &:last-child::after {
      content: none;
    }

    &::before, &::after {
      position: absolute;
      content: "";
      border-bottom: 2px solid #ccc;
      width: 100%;
      top: 0.6rem;
      left: -50%;
      z-index: 2;
    }

    &::after {
      left: 50%;
      width: 0%;
      z-index: 3;
      border-bottom-color: #6200ee;
      transition: width 300ms linear;
    }

    &.active, &.completed {
      .step-name {
        color: #6200ee;
      }
    }

    &.active {
      .step-counter {
        border-color: #6200ee;
      }
    }

    &.completed {
      .step-counter {
        background-color: #6200ee;
        border-color: #6200ee;

        &::before {
          transform: scale(1);
        }
      }

      &::after {
        width: 100%;
      }
    }

    .step-counter {
      background: #eaedf0;
      border-radius: 50%;
      height: 1.35rem;
      width: 1.35rem;
      border: 2px solid #e9ecef;
      z-index: 4;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 0.75rem;
      transition: 300ms linear;

      &::before {
        content: "\f00c";
        transform: scale(0);
        transition: transform 200ms ease-in;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        line-height: 0;
        color: #ffffff;
      }
    }
  }
}
</style>
