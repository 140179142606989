<template>
  <div :class="{ 'modal-header': true, 'modal-header--default': defaultHeader, 'modal-header--dark': dark }">
    <slot>
      <h4 class="modal-title" v-text="title"/>
    </slot>

    <button v-if="showCloseIcon" aria-label="Close" class="close" data-dismiss="modal" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ModalHeader',
  props: {
    title: {
      type: String,
      default: 'Unnamed Modal',
    },
    showCloseIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    dark: {
      type: Boolean,
      required: false,
      default: true,
    },
    defaultHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-header {

  &--default {
    padding: 0.75rem;
    background: #ededed;
    border-radius: 0;
    border: 0;

    :deep(.modal-title) {
      font-weight: 600;
    }
  }

  &:not(&--default) {
    border-radius: 0;
    padding: 0;
    border: none;
  }

  &--dark {
    background: #343a40;
    align-items: center;
    justify-content: space-between;

    > .close {
      padding: 0.25rem 0.45rem;
      display: inline-flex;
      align-items: center;
      background: #ff3347;
      color: #fff;
      float: none;
      text-shadow: none;
      font-weight: normal;
      opacity: 1;
      margin: 0 0.5rem 0 0;
      border-radius: 50%;
      font-size: 1rem;
    }
  }
}
</style>
