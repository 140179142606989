import request from "@services/request";

export const fetchAll = ({ loanNumber }) => request.get(`/applications/${loanNumber}/alerts`);

export const acknowledge = ({ loanNumber, uuid, message }) => {
  return request.put(
    `/applications/${loanNumber}/alerts/${uuid}/acknowledge`,
    { message },
  );
};
