<template>
  <div class="modal-footer">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'ModalFooter',
}
</script>

<style lang="scss" scoped>
.modal-footer {
  padding: 0;
  border-width: 0;
  border-radius: 0;
  background: #c7ccd1;
  flex-wrap: wrap;
  min-height: 2.75rem;
  align-items: unset;

  > .btn {
    border-radius: 0;
    padding: 0.5rem 0;
    max-width: 100%;
    min-width: fit-content;
    flex-grow: 1;
  }

  >:not(:first-child) {
    margin-left: 0;
  }

  >:not(:last-child) {
    margin-right: 0;
  }
}
</style>
