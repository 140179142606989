<template>
  <div>
    <FormGroup label="Employment Details">
      <div class="row">
        <div class="col-md-6">
          <EmploymentStatusSelect v-model="form.employmentStatus"
                                  :error-container="errorContainerName" name="employmentStatus"/>
        </div>
        <div class="col-md-6">
          <FormGroup :error-container="errorContainerName" label="Employer" name="employer">
            <input v-model="form.employer" class="form-control" type="text"/>
          </FormGroup>
        </div>
      </div>
    </FormGroup>
    <FormGroup label="Income">
      <div class="row">
        <div class="col-md-6">
          <FormGroup :error-container="errorContainerName" label="Employment Income (after tax)" name="income">
            <input v-model="form.income" class="form-control" type="number" min="1"/>
          </FormGroup>
        </div>
        <div class="col-md-6">
          <FrequencySelect v-model="form.incomeFrequency"
                           :error-container="errorContainerName" name="incomeFrequency"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <FormGroup :error-container="errorContainerName" label="Other Income" name="otherIncome">
            <input v-model="form.otherIncome" class="form-control" type="number" min="0"/>
          </FormGroup>
        </div>
        <div class="col-md-6">
          <FrequencySelect v-model="form.otherIncomeFrequency"
                           :error-container="errorContainerName" name="otherIncomeFrequency"/>
        </div>
      </div>
    </FormGroup>
    <FormGroup label="Expense">
      <div class="row">
        <div class="col-md-6">
          <FormGroup :error-container="errorContainerName" label="House Expense" name="houseExpense">
            <input v-model="form.houseExpense" class="form-control" type="number" min="1"/>
          </FormGroup>
        </div>
        <div class="col-md-6">
          <FrequencySelect v-model="form.houseExpenseFrequency"
                           :error-container="errorContainerName" name="houseExpenseFrequency"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <FormGroup :error-container="errorContainerName" label="Living Expenses" name="livingExpense">
            <input v-model="form.livingExpense" class="form-control" type="number" min="1"/>
          </FormGroup>
        </div>
        <div class="col-md-6">
          <FrequencySelect v-model="form.livingExpenseFrequency"
                           :error-container="errorContainerName" name="livingExpenseFrequency"/>
        </div>
      </div>
    </FormGroup>

    <slot name="controls" v-bind="{ prev: onPrev, next: onNext }"/>
  </div>
</template>

<script>
import FormGroup from '@modules/shared/components/form/FormGroup';
import {NEW_APPLICATION} from '@store/constants';
import NewApplicationStepMixin from '@modules/guest/new-applicant/components/mixins/NewApplicationStepMixin';
import {validate} from './formValidator';
import EmploymentStatusSelect from '@modules/shared/components/loan/EmploymentStatusSelect';
import FrequencySelect from '@modules/shared/components/loan/FrequencySelect';
import {scrollToErrorFormField} from '@modules/shared/helpers/form';
import store from '@modules/guest/new-applicant/store/store';
import {mergeDeep} from '@modules/shared/helpers/object-fns';
import {saveAsDraft} from '@modules/guest/new-applicant/NewApplicationService';

const ERROR_CONTAINER_NAME = 'NewApplicationEmploymentDetailsStepForm';

const defaultFormState = () => ({
  employmentStatus: '',
  employer: '',
  income: '',
  incomeFrequency: '',
  otherIncome: '',
  otherIncomeFrequency: '',
  houseExpense: '',
  houseExpenseFrequency: '',
  livingExpense: '',
  livingExpenseFrequency: '',
});

export default {
  name: 'EmploymentDetails',
  mixins: [NewApplicationStepMixin],
  data() {
    const original = store.state[NEW_APPLICATION.MODULE_NAME].cache ?? {};

    return {
      form: mergeDeep(
        defaultFormState(),
        original.meta?.EmploymentDetails ?? {},
      ),
    };
  },
  components: {FormGroup, EmploymentStatusSelect, FrequencySelect},
  computed: {
    code() {
      return store.state[NEW_APPLICATION.MODULE_NAME].code;
    },
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler(form) {
        if (form.otherIncome === '') {
          this.form.otherIncomeFrequency = '';
        }
        if (this.shouldGoNextStep()) {
          this.syncWithStore();
        }
      },
    },
  },
  methods: {
    onNext() {
      if (!this.shouldGoNextStep()) {
        scrollToErrorFormField(this.$el);
        return;
      }

      this.saveAndSync().then(async () => {
        await store.dispatch(NEW_APPLICATION.ACTIONS.NEXT_STEP);
      }).catch(e => {
        this.handleServerError(e);
        scrollToErrorFormField(this.$el);
      });
    },
    onPrev() {
      store.dispatch(NEW_APPLICATION.ACTIONS.PREV_STEP);
    },
    async saveAndSync() {
      const payload = {
        step: 'EmploymentDetails',
        ...this.form,
      };

      store.commit({type: NEW_APPLICATION.MUTATIONS.SET_STEPS_META, payload: {loading: true}});

      try {
        const {data: {draft, otp, loanNumber, canPerformCS}} = await saveAsDraft(this.code, payload);
        store.commit({type: NEW_APPLICATION.MUTATIONS.SET_META, payload: {otp, loanNumber, canPerformCS}});
        store.commit({type: NEW_APPLICATION.MUTATIONS.SET_CACHE, payload: draft});
      } catch (e) {
        throw e;
      } finally {
        store.commit({type: NEW_APPLICATION.MUTATIONS.SET_STEPS_META, payload: {loading: false}});
      }
    },
    syncWithStore() {
      const payload = {
        ...this.form,
      };

      this.$store.commit({
        type: NEW_APPLICATION.MUTATIONS.SET_EMPLOYMENT_DETAILS,
        payload,
      });
    },
    validate,
  },
  created() {
    this.registerErrorContainer(ERROR_CONTAINER_NAME);
  },
};
</script>

<style scoped>

</style>
