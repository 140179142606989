import {Str} from '@/utilities/random';

const LOADED_SCRIPTS = {};

const defaultScriptState = () => ({
  key: Str.random({length: 8, unique: true}),
  mounted: false,
  loaded: false,
});

export const loadScript = (url) => {
  const ref = LOADED_SCRIPTS[url] ?? defaultScriptState();

  if (ref?.loaded) {
    return Promise.resolve();
  }

  if (ref?.mounted) {
    const script = document.head.querySelector(`script[data-key="${ref.key}"]`);

    return new Promise((resolve) => {
      script.addEventListener('load', () => {
        ref.loaded = true;

        resolve();
      });
    });
  }

  const script = document.createElement('script');
  script.dataset.key = ref.key;

  return new Promise((resolve) => {
    script.addEventListener('load', () => {
      ref.loaded = true;
      resolve();
    });

    script.src = url;
    script.async = true;
    script.defer = true;

    document.head.append(script);
    ref.mounted = true;

    LOADED_SCRIPTS[url] = ref;
  });
};
