export const Frequencies = {
  weekly: 'Weekly',
  fortnightly: 'Fortnightly',
  monthly: 'Monthly',
};

export const LoanTypes = {
  SACC: 'Mini Flash Loan',
  MACC: 'Super Flash Loan',
};

export const DurationBounds = [
  {min: -Infinity, max: 500, months: 3},
  {min: 500, max: 1000, months: 6},
  {min: 1000, max: 1500, months: 9},
  {min: 1500, max: 2000, months: 12},
  {min: 2000, max: 3500, months: 18},
  {min: 3500, max: Infinity, months: 24},
];

export const LoanPurposes = [
  {
    description: 'Entertainment and leisure (e.g. concert tickets, theme park passes, etc.)',
  },
  {
    description: 'Travel / holiday',
  },
  {
    description: 'Event costs (e.g. wedding, funeral, 21st birthday party)',
  },
  {
    description: 'Vehicle',
  },
  {
    description: 'Household goods and furniture',
  },
  {
    description: 'Home repairs or improvements',
  },
  {
    description: 'Rent / bond / relocation',
  },
  {
    description: 'Utility bills (e.g. gas, electricity, phone)',
    warn: 'It can be useful to talk to your utility provider and see if you can work out a payment plan with them.',
  },
  {
    description: 'Council or water rates / body corporate fees',
  },
  {
    description: 'Insurance (e.g. health, pet, home and contents)',
  },
  {
    description: 'Education Fees (e.g. uni, college or school fees)',
  },
  {
    description: 'Medical / Dental expenses',
    isMedical: true,
  },
  {
    description: 'Professional services fees (eg. lawyer, accountant, veterinarian)',
  },
  {
    description: 'Once-off product purchase (e.g. sporting equipment, tablet dev',
  },
  {
    description: 'Basic living/work expenses (e.g. food, petrol, transport)',
  },
  {
    description: 'Partly/fully repay a current credit card',
    warn: 'It can be useful to talk to your credit provider and see if you can work out a payment plan with them.',
  },
  {
    description: 'Partly/fully repay a current short-term loan',
    warn: 'A short-term loan is a Small Amount Credit Contract (SACC) up to $2000 with a repayment period between 16 days and one year.',
  },
  {
    description: 'Bank loans (e.g. mortgage, personal loan)',
    warn: 'It can be useful to talk to your credit provider and see if you can work out a payment plan with them.',
  },
  {
    description: 'Other (We may give you a call to confirm)',
  },
];

export const Genders = {
  M: 'Male',
  F: 'Female',
};

export const Titles = {
  Mr: 'Mr',
  Mrs: 'Mrs',
  Ms: 'Ms',
  Dr: 'Dr',
  Miss: 'Miss',
};

export const IDVerificationDocumentTypes = {
  passport: 'Passport',
  license: 'Driving Licence',
};

export const StatesOfAustralia = {
  NSW: 'NSW',
  SA: 'SA',
  TAS: 'TAS',
  VIC: 'VIC',
  WA: 'WA',
  QLD: 'QLD',
  NT: 'NT',
  ACT: 'ACT',
};

export const MedicareCardColors = {
  B: 'Blue',
  G: 'Green',
  Y: 'Yellow',
};

export const EmploymentStatus = {
  'Part Time': 'Part Time',
  'Full Time': 'Full Time',
  'Casual': 'Casual',
  'Contract': 'Contract',
  'Self Employed': 'Self Employed',
  'Government Benefits': 'Government Benefits',
};

export const MaritalStatus = {
  Single: 'Single',
  Defacto: 'Defacto',
  Married: 'Married',
  Separated: 'Separated',
  Divorced: 'Divorced',
  Widowed: 'Widowed',
};

export const ResidentialStatus = {
  'Mortgage': 'Mortgage',
  'Renting': 'Renting',
  'Owned': 'Owned',
  'Boarding': 'Boarding',
  'Living with Parents': 'Living with Parents',
};
