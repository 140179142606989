<template>
  <div class="d-flex flex-column" style="gap: 0.5rem">
    <div class="acknowledgement-consent" ref="container">
      <div ref="agreementData" @scroll="onScroll">
        <p>Flash Money Pty Ltd, A.B.N 71 619 737 656, Australian Credit License 508661. I understand that by completing
          an application form that Flash Money Pty Ltd (the entity) will collect, use, hold and disclose my information
          as outlined in the Privacy Policy, which I declare that I have read and understood.</p>

        <h5>Collection of information</h5>
        <p>
          I consent to the entity collecting personal and credit information from me. I understand that if I do not
          provide all information that is requested from me the entity may be unable to provide me with their financial
          services. I also understand that if any of my personal information should change, such as residential address,
          phone number, email address, etc., it is my responsibility to advise the entity of this. I understand it may
          not always be practical or reasonable to collect information from me and as such, I provide my consent for the
          entity to collect personal information about me on an ongoing basis from:
        </p>
        <ol type="i">
          <li>publicly available sources such as public registers;</li>
          <li>referees or point of contacts;</li>
          <li>employers or income providers;</li>
          <li>other financial service providers and financial institutions;</li>
        </ol>
        <p>If any of these parties should request this Acknowledgement and Consent before providing information, I
          consent for this to be provided to them.</p>

        <h5>Use of information</h5>
        <p>I consent to the entity collecting my information for the purposes of providing me with financial services
          and any related purpose including but not limited to:

          lending activities including accepting and assessing loan applications and disbursement of credit;

          credit management functions relating to the management of accounts and collection of overdue amounts; customer
          service requests such as providing access to and updating personal information and resolving disputes;
          gathering information and statistical data to analyze and evaluate business functions;

          developing our service offerings and improving overall business processes and efficiency.
        </p>
        <h5>Disclosure of information</h5>
        <p>I consent to the entity disclosing my personal information to provide me with their financial services and
          any other related purpose as outlined above, on an ongoing basis to:</p>
        <ol type="i">
          <li>my personal point of contacts,</li>
          <li>my employers and income providers;</li>
          <li>other financial institutions and debt collection agencies;</li>
          <li>credit providers, credit brokers, other referrers and other service providers. Financiers, insurers,
            auditors, contractors, agents or service providers the entity uses to offer services;
          </li>
          <li>any other entity.</li>
        </ol>
        <p>I understand my information may also be disclosed in other circumstances outlined in the entity's privacy
          policy.</p>
        <p>I understand the entity may, in the ordinary course of our business, disclose my credit information to third
          parties which operate in the United States of America and while these parties may not, therefore, be subject
          to Australian Privacy Law, no person in those countries will be able to access my credit eligibility
          information without my express consent.</p>
        <h5>Promotion of Products and Services</h5>
        <p>I consent to being contacted by the entity, and any external third parties or agents who act on their behalf,
          for the purposes of the promotion of products and services. I understand the entity may promote the products
          of their marketing partners. I understand that I may withdraw my consent and request not to receive
          promotional communications at any time by contacting the entity.</p>
      </div>

      <div ref="scrollTarget">
        <TransitionGroup class="d-block" mode="out-in" name="slide-fade" tag="div">
          <div v-if="!done" key="see-more"
               class="d-flex justify-content-center align-items-center p-3 see-more"
               @click="scrollBy">
            <span>See More</span>
            <svg class="bi bi-arrow-down-short" fill="currentColor" height="24" viewBox="0 0 16 16"
                 width="24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
                fill-rule="evenodd"/>
            </svg>
          </div>

          <Checkbox v-else key="acknowledgement-checkbox" v-model="proxyValue"
                    class="m-0 d-flex align-items-center acknowledgement-checkbox" style="width: 100%">
            <template v-slot="{ onClick, style }">
              <div :style="style" @click="onClick">
                <div class="d-flex flex-column" style="gap: 0.5rem">
                  <strong style="cursor: pointer">I have read, understood and agree with Flash Money's
                    Acknowledgement and Consent.</strong>
                  <a href="/docs/Acknowledgement and Consent.pdf" target="_blank" @click.stop>Get a copy of consent
                    document.</a>
                </div>
              </div>
            </template>
          </Checkbox>
        </TransitionGroup>
      </div>
    </div>

    <small class="form-text text-muted">
      Scroll to the bottom to acknowledge.
    </small>
  </div>
</template>

<script>
import Checkbox from '@modules/guest/new-applicant/components/form/Checkbox';
import {scrollToElement} from '@modules/shared/helpers/form';

export default {
  name: 'AcknowledgementAndConsent',
  props: ['value'],
  components: {Checkbox},
  data() {
    return {
      done: false,
      initialized: false,
    };
  },
  computed: {
    proxyValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onScroll() {
      if (!this.initialized) {
        return;
      }

      /**
       * @see https://thewebdev.info/2021/02/12/how-to-detect-a-mobile-device-with-javascript/
       * @type {boolean}
       */
      const isMobile = ('ontouchstart' in document.documentElement && !!navigator.userAgent.match(/Mobi/));

      if (isMobile) {
        scrollToElement(this.$refs.container, 'smooth', true);
      }

      if (this.done) {
        return;
      }

      const {offsetHeight, scrollTop, scrollHeight} = this.$refs.agreementData;
      const scrollPercentage = (offsetHeight + scrollTop) / scrollHeight;

      if (scrollPercentage >= 0.99) {
        this.done = true;
      }
    },
    scrollBy() {
      const {offsetHeight} = this.$refs.agreementData;
      this.$refs.agreementData.scrollBy({top: Math.ceil(offsetHeight * 0.85), behavior: 'smooth'});
    },
  },
  mounted() {
    this.$refs.agreementData.scrollTo({top: 0});
    this.initialized = true;
    this.done = false;
  },
};
</script>

<style lang="scss">
.acknowledgement-consent {
  background: #fbfbfb;
  position: relative;
  border: 1px solid #ccc;
  font-size: 0.85rem;

  > div:first-child {
    padding: 0.5rem;
    max-height: 30rem;
    overflow-y: auto;
  }

  h4, h5 {
    font-size: 1rem;
  }

  .acknowledgement-checkbox > .check-box {
    min-height: 4.5rem;
    background: #eee;
    padding: 1rem;
    width: 100%;
  }

  .see-more {
    min-height: 4.5rem;
    background: #eee;
    cursor: pointer;
    color: #0000a0;
    font-weight: 600;
  }
}
</style>
