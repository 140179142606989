<template>
  <div>
    <div class="align-items-center d-flex flex-column">
      <img alt="" class="img img-responsive" src="/images/thankyoutxt.png"
           style="width: 10rem; filter: drop-shadow(0px 0px 7px #000);">

      <div class="end mt-3">
        <p>
          Thank you for making an application with Flash Money! We’re stoked
          to be assisting you.
        </p>
        <p>
          The status of your application will be sent via your login email
          address so make sure you keep an eye out for any emails from
          Flash Money.
        </p>
        <p>
          We’re working super fast to have an answer for you but
          please allow 1 to 2 business days for us to process your application.
        </p>
        <p>
          Alternatively, you can logon to your personal Flash Space by clicking
          on the <a class="green login-link" href="/login">Login</a> button.
        </p>
      </div>

      <slot name="controls" v-bind="{ prev: null, next: null }"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ThankYou',
};
</script>

<style lang="scss" scoped>
.end {
  max-width: 30rem;

  p {
    text-align: justify;
    font-style: italic;
    color: #16062d;
    text-shadow: 1px 1px 4px #666;
  }

  .login-link {
    text-decoration: underline;
    font-size: inherit;
  }
}
</style>
