<template>
  <FormGroup :error-container="errorContainer" label="Employment Status" :name="name">
    <select :disabled="disabled" :value="value" class="form-control" name="employmentStatus"
            @input="$emit('input', $event.target.value)">
      <option v-for="(name, idx) in options" :key="idx" :value="idx" v-text="name"/>
    </select>
  </FormGroup>
</template>

<script>
import FormGroup from "@modules/shared/components/form/FormGroup";
import {EmploymentStatus} from "./constants";

export default {
  name: "EmploymentStatusSelect",
  props: ['value', 'disabled', 'errorContainer', 'name'],
  model: {
    prop: 'value',
    event: 'input',
  },
  components: {FormGroup},
  computed: {
    options: () => EmploymentStatus,
  },
}
</script>

<style scoped>

</style>
