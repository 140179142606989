import Vue from 'vue';
import PortalVue from 'portal-vue';
import store from './store/store.js';
import NewApplicant from './NewApplicant';

Vue.use(PortalVue);

/**
 * @param {Object} options
 */
Vue.prototype.$gaSend = (options) => {
  if (typeof window.ga !== 'function') {
    console.warn('Google Analytics not initialized.');
    return;
  }

  window.ga('send', options);
};

/**
 * @param {string} eventName
 * @param {Object|null} options
 */
Vue.prototype.$gtag = (eventName, options) => {
  if (typeof window.gtag !== 'function') {
    console.warn('Global site tag not initialized.');
    return;
  }

  window.gtag('event', eventName, options);
};

/**
 * @param {Object} properties
 */
Vue.prototype.$gtagSetUserProperties = (properties) => {
  if (typeof window.gtag !== 'function') {
    console.warn('Global site tag not initialized.');
    return;
  }

  window.gtag('set', 'user_properties', properties);
};

new Vue({
  store,
  el: '#new-applicant-app',
  components: {NewApplicant},
});
