<template>
  <div class="d-flex flex-column" style="gap: 0.5rem">
    <FormGroup :error-container="errorContainer" label="Email" name="email">
      <template v-slot="{ id }">
        <div class="d-flex flex-column" style="gap: 0.5rem">
          <input :id="id" ref="input" v-model="form.email" :readonly="meta.loading || meta.codeSent"
                 class="form-control" name="email"
                 placeholder="Enter your email address" required type="email"
                 @blur="form.email = form.email.trim()"
                 @keyup.enter="sendCode"
          />
        </div>

        <TimeCountdown
          v-if="meta.preventResend"
          :disabled="meta.loading"
          :from="60"
          class="form-text text-muted"
          request-change-text="Change Email"
          @requestChange="onRequestEmailChange"
          @resend="sendCode"
        />
      </template>
    </FormGroup>

    <FormGroup v-if="meta.codeSent" :error-container="errorContainer" label="Email Verification Code"
               name="code">
      <template v-slot="{ id }">
        <div class="d-flex flex-column" style="gap: 0.5rem">
          <input :id="id" v-model="form.code" :readonly="meta.loading" class="form-control"
                 name="code" required type="text"/>
        </div>
      </template>
    </FormGroup>

    <button v-if="!meta.codeSent" :disabled="meta.loading"
            class="btn btn-primary d-inline-flex justify-content-center align-items-center"
            style="min-width: 6.75rem; min-height: 2.35rem;"
            type="button"
            @click="sendCode">
      <span v-if="!meta.loading">Send Code</span>
      <SpinKit :loading="meta.loading"/>
    </button>
    <button v-if="meta.codeSent" :disabled="meta.loading"
            class="btn btn-primary d-inline-flex justify-content-center align-items-center"
            style="min-width: 6.75rem; min-height: 2.35rem;"
            type="button"
            @click="verifyCode">
      <span v-if="!meta.loading">Verify Code</span>
      <SpinKit :loading="meta.loading"/>
    </button>
  </div>
</template>

<script>
import TimeCountdown from '@modules/guest/new-applicant/components/steps/ContactVerification/components/TimeCountdown';
import SpinKit from '@components/common/SpinKit';
import FormGroup from '@modules/shared/components/form/FormGroup';
import ServerErrorMixin from '@modules/shared/mixins/ServerErrorMixin';
import store from '@modules/guest/new-applicant/store/store';
import {NEW_APPLICATION} from '@store/constants';
import {sendAccessOTPCode, verifyAccessOTPCode} from '@modules/guest/new-applicant/NewApplicationService';

export default {
  name: 'EmailVerification',
  props: ['errorContainer'],
  mixins: [ServerErrorMixin],
  components: {
    TimeCountdown,
    SpinKit,
    FormGroup,
  },
  computed: {
    code: () => store.state[NEW_APPLICATION.MODULE_NAME].code,
  },
  data() {
    return {
      form: {
        email: '',
        code: '',
      },
      meta: {
        loading: false,
        codeSent: false,
        preventResend: false,
      },
    };
  },
  methods: {
    validate() {
      this.setErrorByKey('email');

      if (!this.$refs.input.validity.valid) {
        this.setErrorByKey('email', 'Email is invalid');
      }
    },
    async sendCode() {
      if (this.meta.loading) {
        return;
      }

      this.validate();

      if (this.hasError('email')) {
        return;
      }

      this.meta.loading = true;
      const payload = {email: this.form.email};

      try {
        await sendAccessOTPCode(this.code, payload);

        this.meta.codeSent = true;
        this.meta.preventResend = true;
      } catch (e) {
        await this.handleServerError(e);
      } finally {
        this.meta.loading = false;
      }
    },
    async verifyCode() {
      if (this.meta.loading) {
        return;
      }

      const error = this.form.code.trim().length === 0 ?
        'Email verification code is required' :
        null;

      this.setErrorByKey('code', error);

      if (this.hasError('code')) {
        return;
      }

      const payload = this.form;
      this.meta.loading = true;

      try {
        await verifyAccessOTPCode(this.code, payload);

        this.$emit('verified');

        this.meta.codeSent = false;
        this.meta.preventResend = false;
        this.form.code = '';
      } catch (e) {
        await this.handleServerError(e);
      } finally {
        this.meta.loading = false;
      }
    },
    onTimeout() {
      this.meta.preventResend = false;
    },
    onRequestEmailChange() {
      this.meta.codeSent = false;
      this.meta.preventResend = false;
    },
  },
};
</script>

<style scoped>

</style>
