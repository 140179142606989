<template>
  <div v-bind="groupAttributes">
    <label v-for="(option, idx) in options" :key="idx" v-bind="buttonAttributes(idx)"
           @click="$emit('input', idx)">
      <input :name="name" :value="idx" autocomplete="off" type="radio"> {{ option }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'RadioButtonGroup',
  props: ['options', 'value', 'name', 'size', 'type', 'block'],
  computed: {
    groupAttributes() {
      return {
        'class': {
          'btn-group': true,
          'btn-group-lg': this.size === 'lg',
          'btn-group-sm': this.size === 'sm',
          'd-block': this.block === true,
          'btn-group-toggle': true,
        },
        'data-toggle': 'buttons',
      };
    },
    buttonAttributes() {
      return (value) => ({
        'class': {
          'btn': true,
          'active': value === this.value,
          [`btn-outline-secondary`]: !this.type,
          [`btn-${this.type}`]: !!this.type,
        },
      });
    },
  },
};
</script>

<style scoped>

</style>
