const store = {
    user: {}
};

export const MUTATIONS = {
  SET_PROFILE: 'SET_PROFILE',
};

export const GETTERS = {
    ADMIN_ID: 'ADMIN_ID',
};

export const ACTIONS = {};

export const MODULE_NAME = 'PROFILE';

export default {
    namespaced: true,
    state: () => store,
    getters: {
        [GETTERS.ADMIN_ID]: ({ user: { user_id } }) => user_id,
    },
    mutations: {
        [MUTATIONS.SET_PROFILE](state, { payload }) {
            state.user = payload;
        }
    }
};