import axios from 'axios';

const bearer = document.head.querySelector('meta[name=access-token]')?.content;
const csrf = document.head.querySelector('meta[name=csrf-token]')?.content;

const headers = {
  'X-Requested-With': 'XMLHttpRequest',
};

if (bearer) {
  headers['Authorization'] = `Bearer ${bearer}`;
}

if (csrf) {
  headers['X-CSRF-TOKEN'] = csrf;
}

export const baseRequest = axios.create({ headers });

export default axios.create({
  headers,
  baseURL: '/api/v2.0/',
});
