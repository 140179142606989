import {fetchAll} from '@modules/admin/loan/alert/services/LoanAlertService';
import {handleError} from '@/utils';

const state = {
  alerts: {},
  overview: {
    total: 0,
    unacknowledged: {
      total: 0,
      hardship: 0,
      critical: 0,
    },
  },
};

export const MUTATIONS = {
  SET_ALERTS: 'SET_ALERTS',
};

export const GETTERS = {
  ALERTS: 'ALERTS',
  OVERVIEW: 'OVERVIEW',
};

export const ACTIONS = {
  FETCH_ALL: 'FETCH_ALL',
};

export const MODULE_NAME = 'LOAN_ALERT';

export default {
  namespaced: true,
  state: () => state,
  getters: {
    [GETTERS.ALERTS]: ({alerts}) => alerts,
    [GETTERS.OVERVIEW]: ({overview}) => overview,
  },
  mutations: {
    [MUTATIONS.SET_ALERTS](state, {payload: {alerts, overview}}) {
      state.alerts = alerts;
      state.overview = overview;
    },
  },
  actions: {
    async [ACTIONS.FETCH_ALL]({commit, rootGetters: GETTERS}) {
      const loanNumber = GETTERS['APPLICATION/LOAN_NUMBER'];

      try {
        const {data: {alerts, overview}} = await fetchAll({loanNumber});

        commit({
          type: MUTATIONS.SET_ALERTS,
          payload: {alerts, overview},
        });
      }
      catch (e) {
        handleError(e);
      }
    },
  },
};
