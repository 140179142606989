import request from "@services/request";
import {MULTIPART_FORM_DATA} from "@constants/RequestHeader";

export const fetchAll = (params = {}) => request.get('/solicitors', {params});

export const create = data => {
    const formData = new FormData();

    Object.keys(data).filter(key => !!data[key]).forEach(key => {
        formData.set(key, data[key]);
    });

    return request.post('/solicitors', formData, {
        headers: {
            ...MULTIPART_FORM_DATA,
        },
    });
}

export const destroy = id => request.delete(`/solicitors/${id}`);

export default {
    fetchAll,
    create,
    destroy,
}
