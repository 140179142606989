import ServerErrorMixin from '@modules/shared/mixins/ServerErrorMixin';

export default {
  data() {
    return {
      form: {},
    };
  },
  mixins: [ServerErrorMixin],
  methods: {
    validate() {
      console.warn(`validate method not defined in ${this?.$options?.name ?? 'unnamed'} step`);
      return {};
    },
    shouldGoNextStep() {
      const errors = this.validate(this.form);

      this.setErrors(errors);

      return this.hasAnyValidationError();
    },
    hasAnyValidationError() {
      const errors = this.validationErrors;
      return !Object.keys(errors).some(field => !!errors[field]);
    }
  },
};
