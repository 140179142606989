import {LoanAmountRange} from '@modules/guest/new-applicant/constants';
import {fetchDraft, saveAsDraft} from '@modules/guest/new-applicant/NewApplicationService';
import {debug} from '@/utils';
import {isObject, mergeDeep} from '@modules/shared/helpers/object-fns';

export const MODULE_NAME = 'NEW_APPLICATION';

const state = {
  code: null,
  cache: null,
  steps: {
    current: 0,
    list: [
      'Loan Details',
      'Contact Verification',
      'More About Yourself',
      'Employment Details',
      'Banking Information',
      'Review',
      'Password',
      'Bank Verification',
      'Done',
    ],
    meta: {
      submitted: false,
      loading: false,
    },
  },
  form: {
    LoanDetails: {
      amount: 500,
      frequency: 'weekly',
      agreeLoanAmountCanChange: null,
      terms: 1,
      purpose: {
        description: '',
        medical: {
          procedure: '',
          practitioner: '',
          contact: '',
        },
        reason: '',
      },
      inabilityToRepay: null,
      inabilityToRepayReason: '',
      acknowledgementAndConsentGranted: false,
    },
    MoreAboutYourself: {
      title: '',
      firstName: '',
      middleName: '',
      lastName: '',
      gender: '',
      dob: '',
      email: '',
      password: '',
      confirmPassword: '',
      australianResident: null,
      medicare: {
        number: '',
        referenceNumber: '',
        color: 'B',
        middleName: '',
        expiryDate: '',
      },
      addresses: {
        current: {
          unit_number: null,
          street_number: null,
          street_name: null,
          suburb: null,
          state: null,
          country: null,
          postcode: null,
          years: null,
          months: null,
        },
        previous: {
          unit_number: null,
          street_number: null,
          street_name: null,
          suburb: null,
          state: null,
          country: null,
          postcode: null,
        },
      },
      mobileNumber: '',
      homePhoneNumber: '',
      idVerification: {
        documentType: '',
        passport: {
          number: '',
          country: '',
        },
        license: {
          number: '',
          state: '',
        },
      },
      agreeIdCheck: false,
    },
    EmploymentDetails: {
      employmentStatus: '',
      employer: '',
      income: '',
      incomeFrequency: '',
      otherIncome: '',
      incomeIncomeFrequency: '',
      houseExpense: '',
      houseExpenseFrequency: '',
      livingExpense: '',
      livingExpenseFrequency: '',
    },
    FinancialCapacity: {
      maritalStatus: '',
      numberOfDependents: '',
      residentialStatus: '',
      banking: {
        accountName: '',
        bsb: '',
        accountNumber: '',
      },
    },
    Review: {
      declareAccurate: false,
      subscribeOffers: false,
      agreeTermsAndConditions: false,
      legal: false,
      jurisdiction: false,
      privacyPolicy: false,
      acknowledgementAndConsent: false,
    },
    BankVerification: {
      appId: '',
    },
    Password: {
      password: '',
      password_confirmation: '',
    },
  },
  meta: {
    analyticsID: null,
    loanNumber: null,
    canPerformCS: false,
    initialized: false,
    otp: false,
    loading: true,
    repaymentAmount: null,
  },
};

export const MUTATIONS = {
  SET_CODE: 'SET_CODE',
  SYNC_FORM: 'SYNC_FORM',
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
  SET_CACHE: 'SET_CACHE',
  SET_META: 'SET_META',
  SET_LOAN_DETAILS: 'SET_LOAN_DETAILS',
  SET_MORE_ABOUT_YOURSELF: 'SET_MORE_ABOUT_YOURSELF',
  SET_EMPLOYMENT_DETAILS: 'SET_EMPLOYMENT_DETAILS',
  SET_FINANCIAL_CAPACITY: 'SET_FINANCIAL_CAPACITY',
  SET_REVIEW: 'SET_REVIEW',
  SET_BANK_VERIFICATION: 'SET_BANK_VERIFICATION',
  SET_OTP_REQUIRED: 'SET_OTP_REQUIRED',
  SET_PASSWORD: 'SET_PASSWORD',
  SET_STEPS_META: 'SET_STEPS_META',
};

export const GETTERS = {
  IS_AUSTRALIAN_RESIDENT: 'IS_AUSTRALIAN_RESIDENT',
  IS_SACC: 'IS_SACC',
  READONLY_INFO: 'READONLY_INFO',
  IS_LOCKED: 'IS_LOCKED',
};

export const ACTIONS = {
  SET_CODE: 'SET_CODE',
  FETCH_DRAFT: 'FETCH_DRAFT',
  SAVE_STEP: 'SAVE_STEP',
  NEXT_STEP: 'NEXT_STEP',
  PREV_STEP: 'PREV_STEP',
  DONE_STEP: 'DONE_STEP',
};

const syncForm = (payload) => ({type: MUTATIONS.SYNC_FORM, payload});
const setCode = (code) => ({type: MUTATIONS.SET_CODE, payload: code});
const setStep = (step) => ({type: MUTATIONS.SET_CURRENT_STEP, payload: step});
const setStepMeta = (meta) => ({type: MUTATIONS.SET_STEPS_META, payload: meta});
const setCache = (cache) => ({type: MUTATIONS.SET_CACHE, payload: cache});
const setMeta = (meta) => ({type: MUTATIONS.SET_META, payload: meta});
const setLoading = (loading = true) => setMeta({loading});

export default {
  namespaced: true,
  state,
  getters: {
    [GETTERS.IS_AUSTRALIAN_RESIDENT]: ({form: {MoreAboutYourself: {australianResident}}}) => australianResident ===
      'yes',
    [GETTERS.READONLY_INFO]: ({cache, meta, form: {LoanDetails, MoreAboutYourself, FinancialCapacity}}, getters) => {
      const moreAboutYourself = isObject(MoreAboutYourself) ? MoreAboutYourself : {};
      const phone = cache?.phone ?? '';
      const email = cache?.email ?? '';

      const fullName = [
        moreAboutYourself.title,
        moreAboutYourself.firstName,
        moreAboutYourself.middleName,
        moreAboutYourself.lastName,
      ].map(name => (name ?? '').trim()).filter(name => !!name).join(' ');

      const addresses = [
        moreAboutYourself?.addresses?.current ?? null,
        moreAboutYourself?.addresses?.previous ?? null,
      ].filter(address => !!address).map((address) => {
        return [
          address?.unit_number ?? null,
          address?.street_number ?? null,
          address?.street_name ?? null,
          address?.suburb ?? null,
          address?.state ?? null,
          address?.postcode ?? null,
          address?.country ?? null,
        ].filter(addressComponent => !!addressComponent)
          .join(' ');
      });

      return {
        loan: {
          amount: LoanDetails.amount,
          terms: LoanDetails.terms,
          frequency: LoanDetails.frequency,
          type: getters[GETTERS.IS_SACC] ? 'Mini Flash Loan' : 'Super Flash Loan',
          repaymentAmount: meta.repaymentAmount,
        },
        user: {
          fullName,
          dob: moreAboutYourself.dob ?? '',
          email: email,
          mobile: phone,
          addresses: addresses,
        },
        fundDistribution: {
          name: FinancialCapacity.accountName,
          bsb: FinancialCapacity.bsb,
          number: FinancialCapacity.accountNumber,
        },
        idVerification: {
          documentType: MoreAboutYourself.idVerification?.documentType ?? '',
          passport: {...MoreAboutYourself.idVerification?.passport ?? {}},
          license: {...MoreAboutYourself.idVerification?.license ?? {}},
          medicare: {...MoreAboutYourself.medicare},
        },
      };
    },
    [GETTERS.IS_SACC]: ({form: {LoanDetails: {amount}}}) => Number(amount) <= 2000,
    [GETTERS.IS_LOCKED]: ({meta}) => !!meta.loanNumber,
  },
  mutations: {
    [MUTATIONS.SET_CODE](state, {payload}) {
      state.code = payload;
    },
    [MUTATIONS.SET_CURRENT_STEP](state, {payload}) {
      state.steps.current = payload;
    },
    [MUTATIONS.SET_CACHE](state, {payload}) {
      state.cache = payload;
    },
    [MUTATIONS.SET_LOAN_DETAILS](state, {payload}) {
      state.form.LoanDetails = payload;
    },
    [MUTATIONS.SET_META](state, {payload}) {
      state.meta = {
        ...state.meta,
        ...payload,
      };
    },
    [MUTATIONS.SET_STEPS_META]({steps}, {payload}) {
      state.steps = {
        ...steps,
        meta: {
          ...steps.meta,
          ...payload,
        },
      };
    },
    [MUTATIONS.SYNC_FORM](state, {payload}) {
      state.cache = payload;

      if (!payload) {
        return;
      }

      state.form = mergeDeep(state.form, payload?.meta ?? {});
    },
    [MUTATIONS.SET_MORE_ABOUT_YOURSELF](state, {payload}) {
      state.form.MoreAboutYourself = payload;
    },
    [MUTATIONS.SET_EMPLOYMENT_DETAILS](state, {payload}) {
      state.form.EmploymentDetails = payload;
    },
    [MUTATIONS.SET_FINANCIAL_CAPACITY](state, {payload}) {
      state.form.FinancialCapacity = payload;
    },
    [MUTATIONS.SET_REVIEW](state, {payload}) {
      state.form.Review = payload;
    },
    [MUTATIONS.SET_BANK_VERIFICATION](state, {payload}) {
      state.form.BankVerification = payload;
    },
    [MUTATIONS.SET_PASSWORD](state, {payload}) {
      state.form.Password = payload;
    },
  },
  actions: {
    [ACTIONS.SET_CODE]({commit, dispatch}, {payload}) {
      commit(setCode(payload));
      dispatch(ACTIONS.FETCH_DRAFT);
    },
    [ACTIONS.NEXT_STEP]({commit, state}) {
      commit(setStep(state.steps.current + 1));
    },
    [ACTIONS.PREV_STEP]({commit, state}) {
      commit(setStep(state.steps.current - 1));
    },
    [ACTIONS.DONE_STEP]({commit, state}) {
      commit(setStepMeta({submitted: true}));

      console.log(state.steps.list.length - 1);

      commit(setStep(state.steps.list.length - 1));
    },
    async [ACTIONS.SAVE_STEP]({commit, state}, {payload}) {
      try {
        commit(setLoading());
        await saveAsDraft(state.code, payload);
      } catch (e) {
        throw e;
      } finally {
        setLoading(false);
      }
    },
    async [ACTIONS.FETCH_DRAFT]({commit, state}) {
      try {
        commit(setLoading(true));

        const {data: {draft, otp, analyticsID, loanNumber, canPerformCS}} = await fetchDraft({code: state.code});

        commit(setMeta({otp, analyticsID, loanNumber, canPerformCS}));
        commit(setStep(loanNumber && canPerformCS ? 7 : (loanNumber ? 8 : 0)));
        commit(setStepMeta({ submitted: !!loanNumber}))

        if (draft && !otp) {
          commit(setMeta({initialized: true}));

          commit(syncForm(draft));
        }

        commit(setCache(draft));
      } catch (e) {
        debug(e);
      } finally {
        commit(setLoading(false));
      }
    },
  },
};
