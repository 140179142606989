<template>
  <div>
    <FormGroup :error-container="errorContainerName" label="New Password" name="password">
      <input v-model="form.password" class="form-control" type="password"/>
    </FormGroup>

    <FormGroup :error-container="errorContainerName" label="Confirm New Password" name="password_confirmation">
      <input v-model="form.password_confirmation" class="form-control" type="password"/>
    </FormGroup>

    <slot name="controls" v-bind="{ prev: onPrev, next: onNext }"/>
  </div>
</template>

<script>
import FormGroup from '@modules/shared/components/form/FormGroup';
import NewApplicationStepMixin from '@modules/guest/new-applicant/components/mixins/NewApplicationStepMixin';
import store from '@modules/guest/new-applicant/store/store';
import {NEW_APPLICATION} from '@store/constants';
import {scrollToErrorFormField} from '@modules/shared/helpers/form';
import {validate} from './formValidator';
import {saveAsDraft} from '@modules/guest/new-applicant/NewApplicationService';

const ERROR_CONTAINER_NAME = 'NewApplicationPasswordStepForm';

export default {
  name: 'Password',
  mixins: [NewApplicationStepMixin],
  components: {
    FormGroup,
  },
  computed: {
    code() {
      return store.state[NEW_APPLICATION.MODULE_NAME].code;
    },
  },
  data() {
    return {
      form: {
        password: '',
        password_confirmation: '',
      },
    };
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler() {
        if (this.shouldGoNextStep()) {
          this.syncWithStore();
        }
      },
    },
  },
  methods: {
    onNext() {
      if (!this.shouldGoNextStep()) {
        scrollToErrorFormField(this.$el);
        return;
      }

      this.saveAndSync().catch(e => {
        this.handleServerError(e);
        scrollToErrorFormField(this.$el);
      });
    },
    async saveAndSync() {
      const payload = {
        step: 'Password',
        ...this.form,
      };

      store.commit({type: NEW_APPLICATION.MUTATIONS.SET_STEPS_META, payload: {loading: true}});

      try {
        const {data: {draft, otp, loanNumber, canPerformCS}} = await saveAsDraft(this.code, payload);

        store.commit({type: NEW_APPLICATION.MUTATIONS.SET_META, payload: {otp, loanNumber, canPerformCS}});
        store.commit({type: NEW_APPLICATION.MUTATIONS.SET_CACHE, payload: draft});

        if (!loanNumber || !canPerformCS) {
          await store.dispatch(NEW_APPLICATION.ACTIONS.DONE_STEP);
          return;
        }

        if (loanNumber && canPerformCS) {
          await store.commit({type: NEW_APPLICATION.MUTATIONS.SET_STEPS_META, payload: {submitted: true}});

          await store.dispatch(NEW_APPLICATION.ACTIONS.NEXT_STEP);
        }
      } catch (e) {
        throw e;
      } finally {
        store.commit({type: NEW_APPLICATION.MUTATIONS.SET_STEPS_META, payload: {loading: false}});
      }
    },
    onPrev() {
      store.dispatch(NEW_APPLICATION.ACTIONS.PREV_STEP);
    },
    syncWithStore() {
      const payload = {
        ...this.form,
      };

      this.$store.commit({
        type: NEW_APPLICATION.MUTATIONS.SET_PASSWORD,
        payload,
      });
    },
    validate,
  },
  created() {
    this.registerErrorContainer(ERROR_CONTAINER_NAME);
  },
};
</script>

<style scoped>

</style>
