<template>
  <FormGroup :error-container="errorContainer" :label="label" :name="name">
    <select v-model="proxyValue" :disabled="disabled" :name="name" class="form-control">
      <option :value="null" :disabled="!allowEmpty" selected>Select</option>
      <option v-for="(name, idx) in options" :key="idx" :value="idx" v-text="name"/>
    </select>
  </FormGroup>
</template>

<script>
import FormGroup from "@modules/shared/components/form/FormGroup.vue";
import Countries from "@modules/shared/constants/countries.js";

export default {
  name: 'CountrySelect',
  components: {FormGroup},
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    name: {
      type: String,
      required: false,
      default: 'country',
    },
    label: {
      type: String,
      required: false,
      default: 'Passport Country',
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: [Boolean, null, undefined],
      required: false,
      default: false,
    },
    errorContainer: {
      type: String,
      required: false,
      default: '',
    },
    allowEmpty: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    options: () => Countries,
    proxyValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  data() {
    return {
      selectedOption: this.value,
    };
  },
}
</script>

<style scoped>

</style>
