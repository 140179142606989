<template>
  <div class="form-group" style="min-height: 2rem;">
    <input v-show="autoCompleteInitialized" :id="id" ref="gAddress" class="form-control"
           placeholder="Enter full address here"
           type="text" @focus="requestGeolocation">
    <div v-show="autoCompleteInitialized" class="form-text text-muted font-italic fs-body">Type your address to get
      auto-complete hint.
    </div>
    <Spinner :loading="!autoCompleteInitialized" theme="dark"/>
  </div>
</template>

<script>
import {loadScript} from '@modules/shared/helpers/script-loader';
import FormGroup from '@modules/shared/components/form/FormGroup';
import {debug} from '@/utils';
import Spinner from '@components/common/Spinner';

const PLACES_SCRIPT_URI = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAcKVE8vfHcIGqoLJmiAYckSUY6YCUVBVk&libraries=places';

const Address = (() => {
  const components = {
    subpremise: {name: 'unit_number', key: 'short_name'},
    street_number: {name: 'street_number', key: 'short_name'},
    route: {name: 'street_name', key: 'long_name'},
    locality: {name: 'suburb', key: 'long_name'},
    administrative_area_level_1: {name: 'state', key: 'short_name'},
    country: {name: 'country', key: 'long_name'},
    postal_code: {name: 'postcode', key: 'short_name'},
  };

  const defaultAddress = () => ({
    unit_number: null,
    street_number: null,
    street_name: null,
    suburb: null,
    state: null,
    country: null,
    postcode: null,
  });

  const keys = Object.keys(components);
  /**
   *
   * @param {String[]} types
   */
  const getKey = (types) => types.find(type => keys.includes(type));

  /**
   *
   * @param {String[]} types
   */
  const getComponent = (types) => components[getKey(types)];

  return {
    /**
     *
     * @param {{ "long_name": string, "short_name": string, "types": string[]}[]} address
     */
    transform(address) {
      return address.reduce(
        (acc, {types, ...rest}) => {
          const component = getComponent(types);

          if (!component || !(component.name in acc)) {
            return acc;
          }

          return {
            ...acc,
            [component.name]: rest[component.key],
          };
        },
        defaultAddress(),
      );
    },
  };
})();

let geoPosition = null;

export default {
  name: 'AddressAutocomplete',
  components: {Spinner, FormGroup},
  props: {
    value: {
      type: Object,
      default: null,
    },
    errorContainer: {
      type: String,
      required: false,
      default: null,
    },
    requestPosition: {
      type: Boolean,
      required: false,
      default: true,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      widgetId: null,
      initialized: false,
      autoCompleteInitialized: false,
      userGeolocation: {
        consentGranted: null,
        position: geoPosition,
        registered: false,
      },
    };
  },
  watch: {
    userGeolocation: {
      deep: true,
      handler({consentGranted, position, registered}) {
        debug(geoPosition ?? {...position});
        if (!this.requestPosition || consentGranted === null || registered || !position) {
          return;
        }

        if (position || geoPosition) {
          this.registerGeolocationPosition();
          return;
        }

        this.requestGeolocation();
      },
    },
  },
  methods: {
    async init() {
      if (this.autoCompleteInitialized) {
        return;
      }

      await loadScript(PLACES_SCRIPT_URI);

      this.autoCompleteInitialized = true;

      this.autocomplete = new google.maps.places.Autocomplete(this.$refs.gAddress, {
        componentRestrictions: {country: 'au'},
        types: ['geocode'],
        fields: ['address_components'],
      });

      this.autocomplete.addListener('place_changed', () => {
        this.$emit('placeChanged', Address.transform(this.autocomplete.getPlace().address_components ?? []));
      });
    },
    requestGeolocation() {
      if (!navigator.geolocation || this.userGeolocation.consentGranted === false) {
        return;
      }

      navigator.geolocation.getCurrentPosition((position) => {
          geoPosition = position;
          this.userGeolocation.position = position;
          this.userGeolocation.consentGranted = true;

          this.registerGeolocationPosition();
        },
        (error) => {
          debug(error.message);
          this.userGeolocation.consentGranted = false;
        },
      );
    },
    registerGeolocationPosition() {
      if (!this.autocomplete || !this.userGeolocation.position || this.userGeolocation.registered) {
        return;
      }

      const position = geoPosition ?? this.userGeolocation.position;

      const circle = new google.maps.Circle({
        center: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
        radius: position.coords.accuracy,
      });

      this.autocomplete.setBounds(circle.getBounds());

      this.userGeolocation.registered = true;
    },
  },
  async mounted() {
    await this.init();
  },
  created() {
    this.autocomplete = null;
  },
};
</script>
