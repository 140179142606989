<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <FormGroup label="Account Name" :error-container="errorContainerName" name="accountName">
          <input :value="form.accountName" class="form-control" type="text" readonly/>
        </FormGroup>
      </div>
      <div class="col-md-6">
        <FormGroup label="BSB" :error-container="errorContainerName" name="bsb">
          <input v-model="form.bsb" class="form-control" type="text"/>
        </FormGroup>
      </div>
      <div class="col-md-6">
        <FormGroup label="Account Number" :error-container="errorContainerName" name="accountNumber">
          <input v-model="form.accountNumber" class="form-control" type="text"/>
        </FormGroup>
      </div>
    </div>

    <slot name="controls" v-bind="{ prev: onPrev, next: onNext }"/>
  </div>
</template>

<script>
import FormGroup from '@modules/shared/components/form/FormGroup';
import {NEW_APPLICATION} from '@store/constants';
import NewApplicationStepMixin from '@modules/guest/new-applicant/components/mixins/NewApplicationStepMixin';
import {validate} from './formValidator';
import {scrollToErrorFormField} from '@modules/shared/helpers/form';
import store from '@modules/guest/new-applicant/store/store';
import {saveAsDraft} from '@modules/guest/new-applicant/NewApplicationService';
import {mergeDeep} from '@modules/shared/helpers/object-fns';

const ERROR_CONTAINER_NAME = 'NewApplicationFinancialCapacityStepForm';

const defaultFormState = () => ({
  accountName: '',
  bsb: '',
  accountNumber: '',
});

export default {
  name: 'BankingInformation',
  mixins: [NewApplicationStepMixin],
  components: {FormGroup},
  data() {
    const original = store.state[NEW_APPLICATION.MODULE_NAME].cache ?? {};

    return {
      form: mergeDeep(
        defaultFormState(),
        original.meta?.BankingInformation ?? {},
      ),
    };
  },
  computed: {
    code() {
      return store.state[NEW_APPLICATION.MODULE_NAME].code;
    },
    accountName() {
      try {
        return store.state[NEW_APPLICATION.MODULE_NAME].cache?.meta?.BankingInformation?.accountName;
      } catch (e) {
        console.error(e);
        return null;
      }
    },
  },
  watch: {
    accountName: {
      immediate: true,
      handler() {
        if (this.accountName) {
          this.form.accountName = this.accountName;
        }
      },
    },
    form: {
      deep: true,
      immediate: true,
      handler() {
        if (this.shouldGoNextStep()) {
          this.syncWithStore();
        }
      },
    },
  },
  methods: {
    onNext() {
      if (!this.shouldGoNextStep()) {
        scrollToErrorFormField(this.$el);
        return;
      }

      this.saveAndSync().then(async () => {
        await store.dispatch(NEW_APPLICATION.ACTIONS.NEXT_STEP);
      }).catch(e => {
        this.handleServerError(e);
        scrollToErrorFormField(this.$el);
      });
    },
    onPrev() {
      store.dispatch(NEW_APPLICATION.ACTIONS.PREV_STEP);
    },
    async saveAndSync() {
      const payload = {
        step: 'BankingInformation',
        ...this.form,
      };

      store.commit({type: NEW_APPLICATION.MUTATIONS.SET_STEPS_META, payload: {loading: true}});

      try {
        const {data: {draft, otp, loanNumber, canPerformCS}} = await saveAsDraft(this.code, payload);
        store.commit({type: NEW_APPLICATION.MUTATIONS.SET_META, payload: {otp, loanNumber, canPerformCS}});
        store.commit({type: NEW_APPLICATION.MUTATIONS.SET_CACHE, payload: draft});
      } catch (e) {
        throw e;
      } finally {
        store.commit({type: NEW_APPLICATION.MUTATIONS.SET_STEPS_META, payload: {loading: false}});
      }
    },
    syncWithStore() {
      const payload = {
        ...this.form,
      };

      this.$store.commit({
        type: NEW_APPLICATION.MUTATIONS.SET_FINANCIAL_CAPACITY,
        payload,
      });
    },
    validate,
  },
  created() {
    this.registerErrorContainer(ERROR_CONTAINER_NAME);
  },
};
</script>

<style scoped>

</style>
