<template>
  <div class="loan-details-step">
    <FormGroup class="loan-amount-slider">
      <VueSlider
        v-model="form.sliderAmount"
        :marks="mark => mark % 250 === 0"
        :max="__amountRange.MAX"
        :min="__amountRange.MIN"
        :interval="50"
        :tooltip-formatter="val => `$ ${Number(val).toFixed(2)}`"
        tooltip="always"
        tooltip-placement="top"
      />
    </FormGroup>

    <div class="display-cards">
      <LoanTypeDisplayCard :amount="form.amount" class="card--loan-display"/>
      <RepaymentAmountDisplayCard :amount="form.amount" :duration="form.terms" :frequency="form.frequency"
                                  class="card--loan-display"/>
    </div>

    <div class="row mt-4">
      <div class="col-md-4">
        <FormGroup :error-container="errorContainerName" label="I Want To Borrow" name="amount">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
  <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
</svg>
              </span>
            </div>
            <select v-model.number="form.amount" class="form-control" name="loan_amount">
              <option v-for="money in __amounts" :key="money" :value="money" v-text="money.toFixed(2)"/>
            </select>
          </div>
        </FormGroup>
      </div>
      <div class="col-md-4">
        <FormGroup :error-container="errorContainerName" label="Loan Duration" name="terms">
          <select v-model="form.terms" class="form-control" name="loan_duration">
            <option v-for="option in loanDurations" :key="option" :value="option"
                    v-text="`${option} Month${option > 1 ? 's' : ''}`"/>
          </select>
        </FormGroup>
      </div>
      <div class="col-md-4">
        <FormGroup :error-container="errorContainerName" label="Frequency" name="frequency">
          <select v-model="form.frequency" class="form-control" name="frequency">
            <option v-for="(name, value) in frequencies" :key="value" :value="value" v-text="name"/>
          </select>
        </FormGroup>
      </div>
    </div>

    <Checkbox v-model="form.agreeLoanAmountCanChange" :error-container="errorContainerName" class="pt-3"
              name="agreeLoanAmountCanChange">
      <template v-slot="{ onClick, style }">
        <div :style="style" @click="onClick">I understand and accept that subject to Flash Money's lending criteria, the
          loan amount and loan term I apply for, may be different from the final offer.
        </div>
      </template>
    </Checkbox>

    <input name="paying_loan_amount" type="hidden" value="0">
    <input id="ga_user_id" :value="ga_user_id" name="ga_user_id" type="hidden">

    <FormGroup :error-container="errorContainerName" label="Loan Purpose" name="purpose.description">
      <LoanPurposePicker v-model="form.purpose.description"/>
    </FormGroup>

    <FormGroup v-if="!!selectedPurpose" :error-container="errorContainerName"
               label="Loan Purpose Additional Description"
               name="purpose.reason">
      <textarea v-model="form.purpose.reason" class="form-control"
                name="loan_purpose_details" rows="5"/>
    </FormGroup>

    <FormGroup :error-container="errorContainerName"
               label="Can you Foresee any reason you won't be able to repay this loan?"
               name="inabilityToRepay">
      <RadioButtonGroup v-model="form.inabilityToRepay" :block="true" :options="{no: 'No', yes: 'Yes'}"
                        name="inability_to_repay"
                        size="sm"/>
    </FormGroup>

    <FormGroup v-if="form.inabilityToRepay === 'yes'" :error-container="errorContainerName"
               label="Please provide the reason"
               name="inabilityToRepayReason">
      <textarea id="reason_for_foresee" v-model="form.inabilityToRepayReason" class="form-control"
                name="reason_for_foresee"/>
    </FormGroup>

    <FormGroup :error-container="errorContainerName" label="Acknowledgement & Consent"
               name="acknowledgementAndConsentGranted">
      <AcknowledgementAndConsent v-model="form.acknowledgementAndConsentGranted"/>
    </FormGroup>

    <slot name="controls" v-bind="{ prev: null, next: onNext }"/>
  </div>
</template>

<script>
import NewApplicationStepMixin from '@modules/guest/new-applicant/components/mixins/NewApplicationStepMixin';
import {
  AmountTerms,
  Frequencies,
  LoanAmountRange,
  LoanPurposes,
  LoanTypes,
} from '@modules/guest/new-applicant/constants';
import RadioButtonGroup from '@modules/shared/components/form/RadioButtonGroup';
import AcknowledgementAndConsent from '../../modals/AcknowledgementAndConsent';
import FormGroup from '@modules/shared/components/form/FormGroup';
import VueSlider from 'vue-slider-component';
import {NEW_APPLICATION} from '@store/constants';
import {validate, validateAmount} from './formValidator';
import '../../scss/vue-slider.scss';
import {scrollToErrorFormField} from '@modules/shared/helpers/form';
import Checkbox from '@modules/guest/new-applicant/components/form/Checkbox';
import store from '@modules/guest/new-applicant/store/store';
import {saveAsDraft} from '@modules/guest/new-applicant/NewApplicationService';
import {mergeDeep} from '@modules/shared/helpers/object-fns';
import RepaymentAmountDisplayCard from './components/RepaymentAmountDisplayCard';
import LoanPurposePicker from '@modules/guest/new-applicant/components/steps/LoanDetails/components/LoanPurposePicker';

const Purposes = LoanPurposes;
const ERROR_CONTAINER_NAME = 'NewApplication::LoanDetailsStepForm';

const AMOUNT_INCREMENT_BY = 50;

const AMOUNT_IN_50_INCREMENT = (({ MAX, MIN }, incrementBy) => {
  const validAmounts = [];
  const maxCount = (MAX - MIN) / incrementBy;

  for (let i = 0; i <= maxCount; i++) {
    validAmounts.push( (i * incrementBy) + MIN);
  }

  return validAmounts;
})(LoanAmountRange, AMOUNT_INCREMENT_BY);

const toNumber = amount => Number(amount);

export const getLoanTypeByAmount = (amount) => toNumber(amount) > 2000 ? 'MACC' : 'SACC';

/**
 * @param {number} amount
 * @return {number[]|[]}
 */
export const generateTerms = (amount) => {
  for (const key in AmountTerms) {
    /**@type {ApprovedMonth} **/
    const terms = Number(key);
    /**@type {TermMatcher} **/
    const match = AmountTerms[terms];

    if (match(amount)) {
      return Array.from({length: terms}, (_, i) => i + 1);
    }
  }

  return [];
};

const LoanTypeDisplayCard = {
  name: 'LoanTypeSelector',
  template: `
    <div class="card bg-transparent border-0">
    <div class="card-body">
      <span v-text="types[type]" class="display-4"/>
      <div class="lead">Loan Type</div>
    </div>
    </div>
  `,
  components: {FormGroup},
  props: ['amount'],
  computed: {
    type() {
      return getLoanTypeByAmount(this.amount) || 'SACC';
    },
    types: () => LoanTypes,
  },
};

const defaultState = () => ({
  amount: LoanAmountRange.MIN,
  frequency: 'weekly',
  sliderAmount: LoanAmountRange.MIN,
  agreeLoanAmountCanChange: null,
  terms: 1,
  purpose: {
    description: Purposes[Purposes.length - 1].description,
    reason: '',
  },
  inabilityToRepay: null,
  inabilityToRepayReason: '',
  acknowledgementAndConsentGranted: false,
});

export default {
  name: 'LoanDetails',
  mixins: [NewApplicationStepMixin],
  components: {
    LoanPurposePicker,
    Checkbox,
    RadioButtonGroup,
    AcknowledgementAndConsent,
    FormGroup,
    LoanTypeDisplayCard,
    RepaymentAmountDisplayCard,
    VueSlider,
  },
  data() {
    const form = mergeDeep(
      defaultState(),
      store.state[NEW_APPLICATION.MODULE_NAME].form.LoanDetails,
      {
        agreeLoanAmountCanChange: null,
        inabilityToRepay: null,
        acknowledgementAndConsentGranted: false,
      },
    );

    form.sliderAmount = form.amount;

    return {form};
  },
  computed: {
    __amountRange: () => LoanAmountRange,
    __amounts: () => [...AMOUNT_IN_50_INCREMENT],
    selectedPurpose() {
      return Purposes.filter(p => p.description === this.form.purpose.description)[0] || null;
    },
    loanDurations() {
      return generateTerms(toNumber(this.form.sliderAmount) || 500);
    },
    frequencies: () => Frequencies,
    ga_user_id() {
      /*@TODO*/
      return 1;
    },
    code() {
      return store.state[NEW_APPLICATION.MODULE_NAME].code;
    },
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler() {
        if (this.shouldGoNextStep()) {
          this.syncWithStore();
        }
      },
    },
    ['form.sliderAmount'](amount) {
      if (validateAmount({amount}) === null) {
        this.form.amount = amount;
      }
    },
    ['form.amount'](amount) {
      if (validateAmount({amount}) === null) {
        this.form.sliderAmount = amount;
      }
    },
    loanDurations(durations) {
      if (!durations.includes(this.form.terms)) {
        this.form.terms = 1;
      }
    },
  },
  methods: {
    onNext() {
      if (!this.shouldGoNextStep()) {
        scrollToErrorFormField(this.$el);
        return;
      }

      this.saveAndSync().then(async () => {
        await store.dispatch(NEW_APPLICATION.ACTIONS.NEXT_STEP);
      }).catch(e => {
        this.handleServerError(e);
        scrollToErrorFormField(this.$el);
      });
    },
    async saveAndSync() {
      const payload = {
        step: 'LoanDetails',
        ...this.form,
      };

      store.commit({type: NEW_APPLICATION.MUTATIONS.SET_STEPS_META, payload: {loading: true}});

      try {
        const {data: {draft, otp, loanNumber, canPerformCS}} = await saveAsDraft(this.code, payload);
        store.commit({type: NEW_APPLICATION.MUTATIONS.SET_META, payload: {otp, loanNumber, canPerformCS}});
        store.commit({type: NEW_APPLICATION.MUTATIONS.SET_CACHE, payload: draft});
      } catch (e) {
        throw e;
      } finally {
        store.commit({type: NEW_APPLICATION.MUTATIONS.SET_STEPS_META, payload: {loading: false}});
      }
    },
    syncWithStore() {
      const payload = {
        ...this.form,
      };

      this.$store.commit({
        type: NEW_APPLICATION.MUTATIONS.SET_LOAN_DETAILS,
        payload,
      });
    },
    validate,
  },
  created() {
    this.registerErrorContainer(ERROR_CONTAINER_NAME);
  },
};
</script>

<style lang="scss">
.loan-details-step {
  margin-top: -2rem;

  @media (max-width: 576px) {
    margin-top: -1.5rem;
  }

  .loan-amount-slider {
    padding: 3rem 4rem;
    background: #fbfbfb;
    margin: 0 -3rem;
    border-bottom: 1px solid #e9ecef;

    @media (max-width: 995.999px) {
      display: none;
    }
  }

  .row--info {
    margin: 0 -3rem;
    border-bottom: 1px solid #e9ecef;
    background: #f8f9fa;
  }

  .card--loan-display {
    .display-4 {
      //font-weight: 400;
      font-size: 2.25rem !important;
      color: #0d001f;
    }

    .lead {
      color: #0d001fbf;
    }
  }

  .display-cards {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    margin: 0 -3rem;
    background: #f3f5f7;
    flex-wrap: wrap;

    > div {
      flex: 1;
    }

    @media (max-width: 576px) {
      margin: 0 -1.5rem;
    }
  }



  @media (max-width: 767.999px) {
    .card--loan-display {
      .display-4 {
        font-weight: 600;
        font-size: 1rem !important;
      }

      .lead {
        font-size: 0.85rem;
        font-weight: 400;
      }

      .card-body {
        padding: 1rem;
      }
    }
  }
}
</style>
