import {success} from "@services/AlertService";
import {destroy, fetchAll, store, update} from "@modules/admin/grace-period/services/GracePeriodService";
import {handleError} from "@/utils";

const state = {
  periods: {},
  editable: {},
};

export const MUTATIONS = {
  SET_PERIODS: 'SET_PERIODS',
  SET_EDITABLE: 'SET_EDITABLE',
  ADD: 'ADD',
  DELETE: 'DELETE',
};

export const GETTERS = {
  PERIODS: 'PERIODS',
  EDITABLE: 'EDITABLE',
};

export const ACTIONS = {
  FETCH_ALL: 'FETCH_ALL',
  DELETE: 'DELETE',
  SAVE: 'SAVE',
};

export const MODULE_NAME = 'GRACE_PERIOD';

export default {
  namespaced: true,
  state: () => state,
  getters: {
    [GETTERS.PERIODS]: ({periods}) => Object.values(periods),
    [GETTERS.EDITABLE]: ({editable}) => editable,
  },
  mutations: {
    [MUTATIONS.SET_PERIODS](state, {payload}) {
      state.periods = payload.reduce((acc, cur) => ({...acc, [cur.id]: cur}), {});
    },
    [MUTATIONS.SET_EDITABLE](state, {payload}) {
      state.editable = payload;
    },
    [MUTATIONS.ADD](state, {payload}) {
      state.periods = { ...state.periods, [payload.id]: payload };
    },
    [MUTATIONS.DELETE](state, {payload: { id }}) {
      delete state.periods[id];
      state.periods = {...state.periods};
    },
  },
  actions: {
    async [ACTIONS.FETCH_ALL]({commit}, {payload}) {
      try {
        const {data: {grace_periods}} = await fetchAll(payload);

        commit({
          type: MUTATIONS.SET_PERIODS,
          payload: grace_periods,
        });
      } catch (e) {
        handleError(e);
      }
    },
    async [ACTIONS.DELETE]({commit}, {payload}) {
      try {
        const {data: {message}} = await destroy(payload.id);

        await success({message});

        commit({
          type: MUTATIONS.DELETE,
          payload
        });
      } catch (e) {
        handleError(e);
      }
    },
    async [ACTIONS.SAVE]({commit}, {payload}) {
      try {
        const {data: {message, grace_period}} = await (payload.id ? update(payload.id, payload) : store(payload));

        await success({message});

        commit({
          type: MUTATIONS.ADD,
          payload: grace_period,
        });
      } catch (e) {
        if (handleError(e)) {
          return Promise.reject(e);
        }
      }
    }
  }
};
