import LoanStateService from '@services/application/LoanStateService'
import { debug } from '@/utils'

const state = () => ({
  loanState: {},
  loading: {
    getState: false,
  },
});

export const MUTATIONS = {
  SET_STATE: 'SET_STATE',
};

export const GETTERS = {
  STATE: 'STATE',
  LOADING: 'LOADING',
};

export const ACTIONS = {
  FETCH_STATE: 'FETCH_STATE',
};

export const MODULE_NAME = 'LOAN_STATE';

export default {
  namespaced: true,
  state,
  getters: {
    [GETTERS.STATE]: ({ loanState }) => loanState,
    [GETTERS.LOADING]: ({ loading }) => loading,
  },
  mutations: {
    [MUTATIONS.SET_STATE](state, {payload}) {
      state.loanState = payload;
    },
    SET_LOADING(state, {payload}) {
      state.loading = {
        ...state.loading,
        ...payload,
      };
    },
  },
  actions: {
    async [ACTIONS.FETCH_STATE]({ commit }, { payload: { loanNumber } }) {
      const setLoading = (payload) => commit({ type: 'SET_LOADING', payload });

      try {
        setLoading({ getState: true });

        const { data: { loanState } } = await LoanStateService.getState({ loanNumber });

        commit({ type: MUTATIONS.SET_STATE, payload: loanState });
      } catch (e) {
        debug(e);
      } finally {
        setLoading({ getState: false });
      }
    },
  }
};
