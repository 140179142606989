const isArray = val => Array.isArray(val);

const ns = (root) => {
  root = isArray(root) ? root : [root];

  return (key) => [...root, key].join('/');
};

export const namespaced = (root, attribs = {}) => {
  const _ns = ns(root);

  return Object.keys(attribs).
    reduce((accumulator, key) => ({
      ...accumulator,
      [key]: _ns(attribs[key]),
    }), {});
}

export const mapSetter = (state, setters = {}) => (
  Object.keys(state).reduce((accumulator, stateName) => {
    const get = state[stateName]
    const set = setters[stateName]

    accumulator[stateName] = { get, set }

    // check if setter exists
    if (!set) {
      delete accumulator[stateName].set
    }

    return accumulator
  }, {})
)
