import {isBetween, isNumber} from '@modules/shared/helpers/form';

export const LoanAmountRange = {
  MIN: 200,
  MAX: 5000,
};

export const Frequencies = {
  weekly: 'Weekly',
  fortnightly: 'Fortnightly',
  monthly: 'Monthly',
};

export const LoanTypes = {
  SACC: 'Mini Flash Loan',
  MACC: 'Super Flash Loan',
};

/** @typedef {2|3|6|8|12|18|24} ApprovedMonth */
/** @typedef {function(number): boolean} TermMatcher */
/** @type {Readonly<{[key: ApprovedMonth]: TermMatcher}>} */
export const AmountTerms = Object.freeze({
  2: amount => amount < 500,
  3: amount => amount === 500,
  6: amount => amount > 500 && amount <= 1000,
  9: amount => amount > 1000 && amount <= 1500,
  12: amount => amount > 1500 && amount <= 2000,
  18: amount => amount > 2000 && amount <= 3500,
  24: amount => amount > 3500,
});

export const LoanPurposes = [
  {
    description: "Vehicle expenses"
  },
  {
    description: "Purchases"
  },
  {
    description: "Utility bills",
    warn: 'It can be useful to talk to your utility provider and see if you can work out a payment plan with them.',
  },
  {
    description: "Travel & entertainment"
  },
  {
    description: "Event expenses"
  },
  {
    description: "Medical expenses",
    isMedical: true,
  },
  {
    description: "Veterinary"
  },
  {
    description: "Educational"
  },
  {
    description: "Professional services fees"
  },
  {
    description: "Home moving & maintenance"
  },
  {
    description: "Debt consolidation",
    warn: 'It can be useful to talk to your credit provider and see if you can work out a payment plan with them.',
  },
  {
    description: "Other",
    warn: 'We may give you a call to confirm'
  }
];

export const Genders = {
  M: 'Male',
  F: 'Female',
};

export const Titles = {
  Mr: 'Mr',
  Mrs: 'Mrs',
  Ms: 'Ms',
  Dr: 'Dr',
  Miss: 'Miss',
};

export const IDVerificationDocumentTypes = {
  passport: 'Passport',
  license: 'Driving License',
};

export const StatesOfAustralia = {
  NSW: 'NSW',
  SA: 'SA',
  TAS: 'TAS',
  VIC: 'VIC',
  WA: 'WA',
  QLD: 'QLD',
  NT: 'NT',
  ACT: 'ACT',
};

export const MedicareCardColors = {
  B: 'Blue',
  G: 'Green',
  Y: 'Yellow',
};

export const EmploymentStatus = {
  'Part Time': 'Part Time',
  'Full Time': 'Full Time',
  'Casual': 'Casual',
  'Contract': 'Contract',
  'Self Employed': 'Self Employed',
  'Government Benefits': 'Government Benefits',
};

export const MaritalStatus = {
  Single: 'Single',
  Defacto: 'Defacto',
  Married: 'Married',
  Separated: 'Separated',
  Divorced: 'Divorced',
  Widowed: 'Widowed',
};

export const ResidentialStatus = {
  'Mortgage': 'Mortgage',
  'Renting': 'Renting',
  'Owned': 'Owned',
  'Boarding': 'Boarding',
  'Living with Parents': 'Living with Parents',
};
