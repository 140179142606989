const state = {
  container: {},
};

export const MUTATIONS = {
  ADD_ERROR_CONTAINER: 'ADD_ERROR_CONTAINER',
  CLEAR_ERROR_CONTAINER: 'CLEAR_ERROR_CONTAINER',
  CLEAR_ALL: 'CLEAR_ALL',
};

export const GETTERS = {
  CONTAINER: 'CONTAINER',
};

export const ACTIONS = {
  REGISTER: 'REGISTER',
};

export const MODULE_NAME = 'ERROR_CONTAINER';

export default {
  namespaced: true,
  state: () => state,
  getters: {
    [GETTERS.CONTAINER]: ({container}) => (key) => {
      return container[key] || {};
    },
  },
  mutations: {
    [MUTATIONS.ADD_ERROR_CONTAINER](state, {payload: {key, errors}}) {
      state.container = {...state.container, [key]: errors};
    },
    [MUTATIONS.CLEAR_ERROR_CONTAINER](state, {payload: {key}}) {
      state.container = {...state.container, [key]: {}};
    },
    [MUTATIONS.CLEAR_ALL](state) {
      state.container = Object.keys(state.container).reduce((all, key) => ({...all, [key]: {}}), {});
    },
  },
  actions: {
    [ACTIONS.REGISTER]({commit, state: {container}}, {payload: {key}}) {
      if (key in container) return;

      commit({
        type: MUTATIONS.ADD_ERROR_CONTAINER,
        payload: {key, errors: {}},
      });
    },
  },
};
