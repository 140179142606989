import request from "@services/request";

export const fetchAll = (loanNumber) => request.get(`/grace-periods/${loanNumber}`);
export const store = (payload) => request.post('/grace-periods', payload);
export const update = (id, payload) => request.put(`/grace-periods/${id}`, payload);
export const destroy = (id) => request.delete(`/grace-periods/${id}`);

export default {
  fetchAll,
  store,
  update,
  destroy,
};
