import dayjs from 'dayjs';

export const isNumber = value => isFinite(Number(value));
export const isPositiveNumber = value => isNumber(value) && Number(value) >= 0;
export const isInteger = value => typeof (+value) === 'number' && !isNaN(+value) && (+value) === Math.ceil(value);
export const isString = value => typeof value === 'string';
export const isBoolean = value => typeof value === 'boolean';
export const isDate = value => dayjs(value).isValid();
export const trim = value => String(value).trim();
export const strlen = value => String(value).length;
export const isBetween = (value, min, max) => value >= min && value <= max;
export const inList = (value, list) => list.includes(value);
export const inObject = (value, object) => object[value] ?? false;
export const isEmpty = value => inList(value, [null, undefined]) || (isString(value) && strlen(trim(value)) === 0);
export const isAlphaNumeric = value => /^[a-zA-Z\d]+$/i.test(value);

const navbar = document.body.querySelector('.navbar');

const isElementInViewport = (el) => {
  const {top, left, right, bottom} = el.getBoundingClientRect();
  const {innerHeight, innerWidth} = window;
  const {clientHeight, clientWidth} = document.documentElement;

  return top >= 0 && left >= 0 && bottom <= (innerHeight || clientHeight) && right <= (innerWidth || clientWidth);
};

export const scrollToElement = (el, behavior = 'smooth', ignoreIsInViewport = true) => {
  const isHTMLElement = el instanceof HTMLElement;
  const shouldScroll = ignoreIsInViewport || isElementInViewport(el);
  if (!isHTMLElement || !shouldScroll) {
    return;
  }

  const top = el.getBoundingClientRect().top + window.scrollY - (navbar?.scrollHeight ?? 0) - 35;

  window.scroll({top, behavior});
};

export const scrollToErrorFormField = (parent, selectors = '.has-error') => {
  if (!(parent instanceof HTMLElement)) {
    return;
  }

  requestAnimationFrame(() => {
    scrollToElement(parent.querySelector(selectors), 'smooth', true);
  });
};

export const msgRequired = (fieldName) => `${fieldName} is required.`;
export const validateRequired = (fieldName, value) => isEmpty(value) ? msgRequired(fieldName) : null;
export const validateInList = (fieldName, value, list) => !inList(value, list) ? `${fieldName} is invalid.` : null;
export const validateInObject = (fieldName, value, object) => !inObject(value, object) ? `${fieldName} is invalid.` : null;
export const validateRequiredInteger = (fieldName, value) => {
  if (isEmpty(value)) {
    return msgRequired(fieldName);
  }

  if (!isInteger(value)) {
    return `${fieldName} is invalid.`;
  }

  return null;
};
export const validateDate = (fieldName, date) => !isDate(date) ? `${fieldName} is invalid.` : null;
export const validateRequiredDate = (fieldName, value) => validateRequired(fieldName, value) ?? validateDate(fieldName, value);
export const validateEmail = (fieldName, email) => {
  if (isEmpty(email)) {
    return msgRequired(fieldName);
  }

  const input = document.createElement('input');
  input.type = 'email';
  input.value = email;

  if (!input.checkValidity()) {
    return `${fieldName} field is invalid.`;
  }

  return null;
};
