<template>
  <div class="lead-loan-application" v-if="loading">
    <div class="in-banner"/>

    <div class="bg-transparent mt-4 pb-2 pt-2 text-center">
      <div class="container-fluid">
        <h1 class="display-4">Loan Application</h1>
        <p class="lead">We've made the application easier than you think.</p>
      </div>
    </div>

    <div class="container container--content">
      <div class="card bg-transparent border-0">
        <div class="card-body p-0 position-relative app-placeholder" style="min-height: 20rem">
          <Spinner theme="dark" :loading="true"/>
          <span class="text-muted font-italic loading-text">Loading</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '@components/common/Spinner';
export default {
  name: 'LoadingPlaceholder',
  props: {
    loading: {
      type: Boolean,
      required: true,
    }
  },
  components: {Spinner},
};
</script>

<style scoped lang="scss">
.app-placeholder {
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  .loading-text {
    font-size: 0.85rem;
    font-weight: 600;

    &::after {
      content: "...";
      width: 0;
      display: inline-block;
      white-space: nowrap;
    }
  }
}
</style>
