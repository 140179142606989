import PAYMENT_TYPE, { MODULE_NAME as PAYMENT_TYPE_MODULE_NAME } from "./modules/payment-types";

const state = {};

export const MUTATIONS = {};

export const GETTERS = {};

export const ACTIONS = {};

export const MODULE_NAME = 'ADDITIONAL_PAYMENTS';

export default {
    namespaced: true,
    state: () => state,
    modules: {
        [PAYMENT_TYPE_MODULE_NAME]: PAYMENT_TYPE,
    },
};