<template>
  <div>
    <FormGroup label="Loan Details">
      <div class="table-responsive">
        <table class="table table-sm table-striped table-bordered">
          <thead>
          <tr>
            <th>Loan Amount</th>
            <th>Duration</th>
            <th>Type</th>
            <th>Repayment Amount</th>
            <th>Frequency</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{ readonlyInfo.loan.amount }}</td>
            <td>{{ readonlyInfo.loan.terms + ' Month' + (readonlyInfo.loan.terms > 1 ? 's' : '') }}</td>
            <td>{{ readonlyInfo.loan.type }}</td>
            <td v-if="repaymentAmount !== null">{{ repaymentAmount }} (approx.)</td>
            <td v-else class="text-danger">error encountered</td>
            <td>{{ readonlyInfo.loan.frequency }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </FormGroup>

    <FormGroup
      v-if="isSACC"
      label="Mini Flash Loan Fees (If the principal amount you borrow is between $200 and $2,000)">
      <p>You will only be charged the loan establishment fee and the monthly loan fee when making payments on time. If
        you miss any repayments, a Dishonour and Default Fee will apply.</p>
      <table class="table table-sm table-striped table-bordered">
        <tr>
          <th colspan="2">Loan fees and charges</th>
        </tr>
        <tr>
          <td>Establishment Fee<br>Payable once off per loan</td>
          <td>20% of the principal</td>
        </tr>
        <tr>
          <td>Monthly Loan Fee <br>
            Where there is an outstanding debt balance, charged per month of part thereof
          </td>
          <td>4% of the principal per month</td>
        </tr>
        <tr>
          <th colspan="2">Fees that may apply</th>
        </tr>
        <tr>
          <td>Dishonour Fee <br>
            Charged each time a direct debit or other payment is not made in full or on time. This fee includes any
            reasonable internal and administrative costs and external third party costs.
          </td>
          <td>$20.00</td>
        </tr>
        <tr>
          <td>Default Fee <br>
            Daily recovery fee to cover reasonable costs of administering your account while your account remains in
            default.
          </td>
          <td>$5.00</td>
        </tr>
      </table>
    </FormGroup>

    <FormGroup v-else label="Super Flash Loan Fees (If the principal amount you borrow in between $2,001 and $5,000)">
      <p>
        You will only be charged the loan establishment fee and the interest charges calculated based on the
        applicable interest rate when making payments on time. If you miss any repayments,
        a Dishonour and Reschedule Fee will apply.
      </p>

      <table class="table table-sm table-striped table-bordered">
        <tr>
          <th colspan="2">Loan fees and charges</th>
        </tr>
        <tr>
          <td>Establishment Fee<br>Payable once off per loan</td>
          <td>$400.00</td>
        </tr>
        <tr>
          <td>Interest Rate<br>
            This is the Annual Percentage Rate. Interest accrues daily - and is charged monthly in arrears -
            on the outstanding balance of your loan.
          </td>
          <td>47.6158% p.a.</td>
        </tr>
        <tr>
          <td>Comparison Rate*</td>
          <td>65.6597% p.a.</td>
        </tr>
        <tr>
          <th colspan="2">Fees that may apply</th>
        </tr>
        <tr>
          <td>Dishonour Fee <br>
            Charged each time a direct debit or other payment is not made in full or on time. This fee includes any
            reasonable internal and administrative costs and external third party costs.
          </td>
          <td>$20.00</td>
        </tr>
        <tr>
          <td>Reschedule Fee <br>
            Charged each time a repayment is rescheduled. This is a service fee which covers our reasonable internal
            administrative costs.
          </td>
          <td>$20.00</td>
        </tr>
      </table>
    </FormGroup>

    <FormGroup label="User Detail">
      <div class="table-responsive">
        <table class="table table-sm table-striped table-bordered">
          <thead>
          <tr>
            <th>Full Name</th>
            <th>Date of Birth</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Address</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{ readonlyInfo.user.fullName }}</td>
            <td>{{ readonlyInfo.user.dob }}</td>
            <td>{{ readonlyInfo.user.email }}</td>
            <td>{{ readonlyInfo.user.mobile }}</td>
            <td>{{ readonlyInfo.user.addresses[0] }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </FormGroup>

    <FormGroup label="Banking Information">
      <table class="table table-sm table-striped table-bordered">
        <thead>
        <tr>
          <th>Account Name</th>
          <th>BSB</th>
          <th>Account Number</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{ readonlyInfo.fundDistribution.name }}</td>
          <td>{{ readonlyInfo.fundDistribution.bsb }}</td>
          <td>{{ readonlyInfo.fundDistribution.number }}</td>
        </tr>
        </tbody>
      </table>
    </FormGroup>

    <FormGroup label="ID Verification">
      <table v-if="readonlyInfo.idVerification.documentType === 'passport'"
             class="table table-sm table-striped table-bordered">
        <thead>
        <tr>
          <th>Passport</th>
          <th>Country</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{ readonlyInfo.idVerification.passport.number }}</td>
          <td>{{ readonlyInfo.idVerification.passport.country }}</td>
        </tr>
        </tbody>
      </table>
      <table v-else class="table table-sm table-striped table-bordered">
        <thead>
        <tr>
          <th>License Number</th>
          <th>License Card Number</th>
          <th>State</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{ readonlyInfo.idVerification.license.number }}</td>
          <td>{{ readonlyInfo.idVerification.license.cardNumber }}</td>
          <td>{{ readonlyInfo.idVerification.license.state }}</td>
        </tr>
        </tbody>
      </table>

      <table v-if="isAustralianCitizen" class="table table-sm table-striped table-bordered">
        <thead>
        <tr>
          <th>Medicare Card Number</th>
          <th>Reference</th>
          <th>Color</th>
          <th>Expiry</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{ readonlyInfo.idVerification.medicare.number }}</td>
          <td>{{ readonlyInfo.idVerification.medicare.reference }}</td>
          <td>{{ readonlyInfo.idVerification.medicare.color }}</td>
          <td>{{ readonlyInfo.idVerification.medicare.expiry }}</td>
        </tr>
        </tbody>
      </table>

      <p>
        This guide does not constitute legal advice. We encourage you to seek your own professional advice to find out
        how the Corporations Act and other applicable laws apply to you, as it is your responsibility to determine your
        obligations. Please speak to your financial advisor, lawyer or visit
        <a href="https://moneysmart.gov.au/" target="_blank">www.moneysmart.gov.au</a> before making a decision.
      </p>

      <p>
        <strong>More Information</strong><br> For general financial information and calculators please visit <a
        href="https://moneysmart.gov.au/" target="_blank">www.moneysmart.gov.au</a> or visit our website
        <a href="https://www.flashmoney.com.au" target="_blank">www.flashmoney.com.au</a>
      </p>
    </FormGroup>

    <Checkbox v-model="form.declareAccurate" :error-container="errorContainerName" name="declareAccurate">
      <template v-slot="{ onClick, style }">
        <div :style="style" @click="onClick">I declare that the information I have provided is true and accurate.</div>
      </template>
    </Checkbox>

    <Checkbox v-model="form.consentThirdParty" :error-container="errorContainerName" name="consentThirdParty">
      <template v-slot="{ onClick, style }">
        <div :style="style" @click="onClick">
          I confirm that I am authorised to provide the personal details presented and I consent to my information being checked with the document issuer or official record holder via third party systems for the purpose of confirming my identity.
        </div>
      </template>
    </Checkbox>

    <Checkbox v-model="form.subscribeOffers" :error-container="errorContainerName" name="subscribeOffers">
      <template v-slot="{ onClick, style }">
        <div :style="style" @click="onClick">I would like to hear from Flash Money about loan offers that may interest
          me.
        </div>
      </template>
    </Checkbox>

    <Checkbox v-model="form.agreeTermsAndConditions" :error-container="errorContainerName" name="agreeTermsAndConditions">
      <template v-slot="{ onClick, style }">
        <div :style="style" @click="onClick">
          I agree to the Terms and Conditions of <a class="green" @click.stop href="/docs/Flash Money Direct Debit Authority &amp; Service Agreement.pdf" target="_blank">Flash Money's Direct Debit Request and Service Agreement</a>.
        </div>
      </template>
    </Checkbox>

    <Checkbox v-model="form.legal" :error-container="errorContainerName" name="legal">
      <template v-slot="{ onClick, style }">
        <div :style="Object.assign({ gap: '1rem' }, style)" @click="onClick" class="d-flex flex-column flex-nowrap align-items-start">
          <p><strong>Legal</strong></p>
          <p class="content">
            I acknowledge that a person who commits a fraud by deception or dishonesty or makes a false document with
            the intention that a person or entity or another party will use it to induce some person or entity or
            another party to accept it as genuine, and because of its being accepted as genuine obtain any property
            belonging to another, or to obtain any financial advantage or cause any financial disadvantage, or to
            influence the exercise of a public duty, is guilty of the criminal offence and such a person will be
            referred to the relevant law enforcement authority and criminally prosecuted.
          </p>
          <p class="content">
            Pursuant to the Oaths Act 1900 (NSW) I, do hereby solemnly swear, declare and affirm, that all the
            information provided by me in my application to Flash Money Pty Ltd is truthful, correct and accurate –
            according to the law in this behalf made – and subject to the punishment by law provided for any wilfully
            false statement or information within this application.
          </p>
        </div>
      </template>
    </Checkbox>

    <Checkbox v-model="form.jurisdiction" :error-container="errorContainerName" name="jurisdiction">
      <template v-slot="{ onClick, style }">
        <div :style="Object.assign({ gap: '1rem' }, style)" @click="onClick" class="d-flex flex-column flex-nowrap align-items-start">
          <p><strong>Jurisdiction</strong></p>
          <p class="content">
            All parties to this agreement irrevocably agrees that the courts of New South Wales Australia shall have
            exclusive jurisdiction to hear, settle and/or determine any dispute, controversy or claim (including any
            non-contractual dispute, controversy or claim) arising out of or in connection with this agreement,
            including any question regarding its existence, validity, formation or termination. For these and all
            purposes, each party irrevocably submits to the jurisdiction of the New South Wales Courts, Australia.
          </p>
        </div>
      </template>
    </Checkbox>

    <Checkbox v-model="form.privacyPolicy" :error-container="errorContainerName" name="privacyPolicy">
      <template v-slot="{ onClick, style }">
        <div :style="style" @click="onClick">
          I have reviewed the <a class="green" @click.stop href="/docs/Privacy Policy - Credit Providers (APP entities).pdf" target="_blank">Privacy Policy</a> and I understand and accept all terms they set out.
        </div>
      </template>
    </Checkbox>

    <Checkbox v-model="form.acknowledgementAndConsent" :error-container="errorContainerName" name="acknowledgementAndConsent">
      <template v-slot="{ onClick, style }">
        <div :style="style" @click="onClick">
          <p class="content">
            I have read and understood the <a @click.stop class="green" href="/docs/Acknowledgement and Consent.pdf" target="_blank">Acknowledgement and Consent </a>
            and make all consent and acknowledgement it contains, including consenting to:an electronic
            identity verification, promotion of products and services, information exchange with other providers and a
            credit check.
          </p>
        </div>
      </template>
    </Checkbox>

    <slot name="controls" v-bind="{ prev: onPrev, next: onNext }"/>
  </div>
</template>

<script>
import FormGroup from '@modules/shared/components/form/FormGroup';
import {NEW_APPLICATION} from '@store/constants';
import NewApplicationStepMixin from '@modules/guest/new-applicant/components/mixins/NewApplicationStepMixin';
import {validate} from './formValidator';
import ErrorText from '@modules/shared/components/form/ErrorText';
import {scrollToErrorFormField} from '@modules/shared/helpers/form';
import store from '@modules/guest/new-applicant/store/store';
import {saveAsDraft} from '@modules/guest/new-applicant/NewApplicationService';
import Checkbox from '@modules/guest/new-applicant/components/form/Checkbox';

const ERROR_CONTAINER_NAME = 'NewApplicationReviewStepForm';

export default {
  name: 'Review',
  mixins: [NewApplicationStepMixin],
  components: {Checkbox, ErrorText, FormGroup},
  data() {
    return {
      form: {
        declareAccurate: false,
        consentThirdParty: false,
        subscribeOffers: false,
        agreeTermsAndConditions: false,
        legal: false,
        jurisdiction: false,
        privacyPolicy: false,
        acknowledgementAndConsent: false,
      },
    };
  },
  computed: {
    code() {
      return store.state[NEW_APPLICATION.MODULE_NAME].code;
    },
    readonlyInfo() {
      return this.$store.getters[NEW_APPLICATION.GETTERS.READONLY_INFO];
    },
    repaymentAmount() {
      const amount = this.readonlyInfo.loan.repaymentAmount;

      return typeof amount !== 'number' ? null : Number(amount).toFixed(2);
    },
    review() {
      return this.$store.state[NEW_APPLICATION.MODULE_NAME].form.Review;
    },
    isSACC() {
      return this.$store.getters[NEW_APPLICATION.GETTERS.IS_SACC];
    },
    isAustralianCitizen() {
      return this.$store.getters[NEW_APPLICATION.GETTERS.IS_AUSTRALIAN_RESIDENT];
    },
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler() {
        this.shouldGoNextStep();
        this.syncWithStore();
      },
    },
  },
  methods: {
    onNext() {
      if (!this.shouldGoNextStep()) {
        scrollToErrorFormField(this.$el);
        return;
      }

      this.saveAndSync().then(async () => {
        await store.dispatch(NEW_APPLICATION.ACTIONS.NEXT_STEP);
      }).catch(e => {
        this.handleServerError(e);
        scrollToErrorFormField(this.$el);
      });
    },
    async saveAndSync() {
      const payload = {
        step: 'Review',
        ...this.form,
      };

      store.commit({type: NEW_APPLICATION.MUTATIONS.SET_STEPS_META, payload: {loading: true}});

      try {
        const {data: {draft, otp, loanNumber, canPerformCS}} = await saveAsDraft(this.code, payload);
        store.commit({type: NEW_APPLICATION.MUTATIONS.SET_META, payload: {otp, loanNumber, canPerformCS}});
        store.commit({type: NEW_APPLICATION.MUTATIONS.SET_CACHE, payload: draft});
      } catch (e) {
        throw e;
      } finally {
        store.commit({type: NEW_APPLICATION.MUTATIONS.SET_STEPS_META, payload: {loading: false}});
      }
    },
    onPrev() {
      store.dispatch(NEW_APPLICATION.ACTIONS.PREV_STEP);
    },
    syncWithStore() {
      const payload = {
        ...this.form,
      };

      this.$store.commit({
        type: NEW_APPLICATION.MUTATIONS.SET_REVIEW,
        payload,
      });
    },
    validate,
  },
  created() {
    this.registerErrorContainer(ERROR_CONTAINER_NAME);
  },
};
</script>

<style lang="scss" scoped>
.table-bordered td, .table-bordered th {
  border-color: #dee2e6;
}

.form-group {
  p {
    margin: 0;
    padding: 0;
  }

  .content {
    text-align: justify;
  }
}
</style>
