<template>
  <SmoothReflow class="form-text text-danger m-0" :options="{property: ['height', 'margin-top']}">
    <span v-if="hasError(name)" v-text="getErrorText(name)" class="d-block pt-2" style="font-size: 0.9rem;"/>
  </SmoothReflow>
</template>

<script>
import ServerErrorMixin from '@modules/shared/mixins/ServerErrorMixin';
import SmoothTransitionMixin from '@modules/shared/mixins/SmoothTransitionMixin';
import SmoothReflow from '@modules/shared/transitions/SmoothReflow';

export default {
  name: 'ErrorText',
  components: {SmoothReflow},
  mixins: [ServerErrorMixin, SmoothTransitionMixin],
  props: {
    name: {
      type: String,
      required: true,
    },
    errorContainer: {
      required: true,
    },
  },
  watch: {
    errorContainer: {
      handler(value) {
        if (value) {
          this.registerErrorContainer(value);
        }
      },
      immediate: true,
    },
  },
};
</script>

