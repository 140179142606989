import SweetAlert from "sweetalert2";

export const confirm = ({question, confirmButtonText}) => {
  return SweetAlert.fire({
    titleText: 'Please Confirm',
    text: question,
    type: 'question',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: confirmButtonText ? confirmButtonText : 'Ok',
    cancelButtonText: 'Cancel',
  });
}

export const confirmProcess = (title, text, confirm, cancel = 'No', showCancelButton = true) => {
  return SweetAlert.fire({
    titleText: title,
    text: text,
    type: 'info',
    showConfirmButton: true,
    showCancelButton: showCancelButton ?? false,
    confirmButtonText: confirm,
    cancelButtonText: cancel,
  });
}

export const success = ({message}) => {
  return SweetAlert.fire({
    titleText: 'success',
    text: message,
    type: 'success',
  });
}

export const html = ({html, target}) => {
  return SweetAlert.fire({
    html,
    target,
    showConfirmButton: false,
    customClass: {
      title: 'hide',
      icon: 'hide',
      actions: 'hide',
    },
  });
}

export const error = ({message}) => {
  return SweetAlert.fire({
    titleText: 'Error',
    text: message,
    type: 'error'
  });
}

export const errorP2 = (title, text, confirm, cancel = 'No', showCancelButton = true) => {
  return SweetAlert.fire({
    titleText: title,
    text: text,
    type: 'error',
    showConfirmButton: true,
    showCancelButton: showCancelButton ?? false,
    confirmButtonText: confirm,
    cancelButtonText: cancel,
  });
}

export const image = imageUrl => SweetAlert.fire({
  imageUrl,
  width: '50rem',
  padding: 0,
  customClass: {
    actions: 'hide',
    image: 'p-0 m-0'
  },
});

export const fire = SweetAlert.fire;

export default {
  confirm,
  confirmProcess,
  error,
  errorP2,
  success,
  image,
  html,
  fire,
}
