const CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

const shuffleCharset = () => CHARSET.split('').sort(() => (Math.random() > .5) ? 1 : -1).join('');

export class Str {
  static #bucket = [];

  static #exists = (str) => Str.#bucket.includes(str);
  static #append = (str) => Str.#bucket.push(str);

  static #make = (length) => {
    const chars = shuffleCharset();

    let result = '';

    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * length));
    }

    return result;
  };

  static random({length, unique} = {length: 6, unique: true}) {
    const str = Str.#make(length);

    if (unique && Str.#exists(str)) {
      return Str.random({length, unique});
    }

    Str.#append(str);

    return str;
  }
}
