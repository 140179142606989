<template>
  <div class="card bg-transparent border-0">
    <div class="card-body">
      <span v-if="loading">Loading...</span>
      <span v-if="!loading && repaymentAmount === null" v-text="'Error encountered.'" class="display-4 text-danger"/>
      <span v-if="!loading && repaymentAmount !== null" v-text="`$${repaymentAmount.toFixed(2)}`" class="display-4"/>
      <div class="lead">Repayment Amount (Approx.)</div>
    </div>
  </div>
</template>

<script>
import FormGroup from '@modules/shared/components/form/FormGroup';
import {debounce} from 'lodash/function';
import {isBetween} from '@modules/shared/helpers/form';
import axios from 'axios';
import store from '@modules/guest/new-applicant/store/store';
import {NEW_APPLICATION} from '@store/constants';

export default {
  name: 'RepaymentAmountDisplayCard',
  components: {FormGroup},
  props: ['amount', 'frequency', 'duration'],
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    repaymentAmount() {
      return store.state[NEW_APPLICATION.MODULE_NAME].meta.repaymentAmount;
    },
    debouncedGetRepaymentAmount() {
      return debounce(this.getRepaymentAmount, 200);
    },
  },
  watch: {
    amount: {
      handler() {
        this.debouncedGetRepaymentAmount();
      },
    },
    frequency: {
      handler() {
        this.debouncedGetRepaymentAmount();
      },
    },
    duration: {
      handler() {
        this.debouncedGetRepaymentAmount();
      },
    },
  },
  methods: {
    async getRepaymentAmount() {
      const loan_amount = Number(this.amount);
      this.loading = true;

      if (!loan_amount || !isBetween(loan_amount, 200, 5000) || !this.duration || !this.frequency) {
        store.commit({ type: NEW_APPLICATION.MUTATIONS.SET_META, payload: {repaymentAmount: null}});
        return;
      }

      try {
        const {data} = await axios.post('/api/getRepaymentAmount', {
          loan_amount: this.amount,
          loan_duration: this.duration,
          frequency: this.frequency,
        });

        store.commit({ type: NEW_APPLICATION.MUTATIONS.SET_META, payload: {repaymentAmount: data}});
      } catch (e) {
        store.commit({ type: NEW_APPLICATION.MUTATIONS.SET_META, payload: {repaymentAmount: null}});
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getRepaymentAmount();
  },
};
</script>

<style scoped>

</style>
