<template>
  <div class="more-about-yourself-step">
    <div class="row">
      <div class="col-md-4">
        <FormGroup :error-container="errorContainerName" label="First Name" name="firstName">
          <input v-model="form.firstName" class="form-control" name="firstName"
                 type="text"/>
        </FormGroup>
      </div>
      <div class="col-md-4">
        <FormGroup label="Middle Name">
          <input v-model="form.middleName" class="form-control" name="middleName"
                 type="text"/>
        </FormGroup>
      </div>
      <div class="col-md-4">
        <FormGroup :error-container="errorContainerName" label="Last Name" name="lastName">
          <input v-model="form.lastName" class="form-control" name="lastName"
                 type="text"/>
        </FormGroup>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2">
        <GenderSelect v-model="form.gender" :error-container="errorContainerName"/>
      </div>
      <div class="col-md-5">
        <FormGroup :error-container="errorContainerName" label="Date of Birth" name="dob">
          <input v-model="form.dob" :max="today" class="form-control" name="dob" type="date"/>
        </FormGroup>
      </div>
      <div class="col-md-5">
        <MaritalStatusSelect v-model="form.maritalStatus"/>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <FormGroup :error-container="errorContainerName" label="Number of Dependants" name="numberOfDependents">
          <select v-model.number="form.numberOfDependents" class="form-control" name="numberOfDependents">
            <option v-for="num in numberOfDependentsOptions" :key="num" :value="num" v-text="num"/>
          </select>
        </FormGroup>
      </div>

      <div class="col-md-8">
        <ResidentialStatusSelect v-model="form.residentialStatus"/>
      </div>
    </div>

    <div class="mb-3" style="padding: 1rem; background: #f1f1f1;">
      <Checkbox v-model="form.australianResident" :error-container="errorContainerName" name="australianResident">
        <template v-slot="{ onClick, style }">
          <div :style="style" @click="onClick">I am an Australian Citizen or Permanent Resident of Australia.</div>
        </template>
      </Checkbox>

      <FormGroup label="Medicare Information">
        <div class="row fs-body">
          <div class="col-md-6">
            <FormGroup :error-container="errorContainerName" label="Medicare Card Number"
                       name="medicare.number">
              <input v-model="form.medicare.number"
                     class="form-control"
                     name="medicareCardNumber" type="text"/>
            </FormGroup>
          </div>
          <div class="col-md-6">
            <FormGroup :error-container="errorContainerName" label="Medicare Reference Number"
                       name="medicare.reference">
              <select v-model="form.medicare.reference" class="form-control" name="medicareReferenceNumber">
                <option :value="null" disabled>Select</option>
                <option v-for="num in 9" :key="num" :value="num" v-text="num"/>
              </select>
            </FormGroup>
          </div>
          <div class="col-md-4">
            <FormGroup :error-container="errorContainerName" label="Medicare Card Color" name="medicare.color">
              <RadioButtonGroup v-model="form.medicare.color" :block="true"
                                :options="{G: 'Green', B: 'Blue',  Y: 'Yellow'}"
                                name="medicareCardColor"
                                size="sm" type="outline-secondary"/>
            </FormGroup>
          </div>
          <div class="col-md-4">
            <FormGroup label="Medicare Middle Name">
              <input v-model="form.medicare.middleName" class="form-control" name="medicareMiddleName"
                     type="text"/>
            </FormGroup>
          </div>
          <div class="col-md-4">
            <FormGroup :error-container="errorContainerName" label="Medicare Expiry"
                       name="medicare.expiry">
              <select v-model="form.medicare.expiry" class="form-control" name="medicareExpiry">
                <option v-for="({ key, value }) in medicareExpiryOptions" :key="key" :value="value" v-text="key"/>
              </select>
            </FormGroup>
          </div>
        </div>
      </FormGroup>
    </div>

    <AddressInput v-model="form.addresses.current" :error-container="errorContainerName"
                  error-key-prefix="addresses.current."
                  name="Current Address"/>

    <SmoothReflow :show="isPreviousAddressRequired">
      <AddressInput v-model="form.addresses.previous" :duration="false"
                    :error-container="errorContainerName" error-key-prefix="addresses.previous."
                    name="Previous Address">
        <template v-slot:header-text>
          <p class="d-flex align-items-center bg-warning p-3 fs-body" style="gap: 0.5rem; margin: -1rem -1rem 1rem">
            <svg class="bi bi-exclamation-diamond-fill" fill="currentColor" height="16"
                 style="min-width: 1rem; max-width: 1rem; width: 1rem;" viewBox="0 0 16 16" width="16"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </svg>

            <span>
            Previous address is required if current address is less than 1 year.
          </span>
          </p>
        </template>
      </AddressInput>
    </SmoothReflow>

    <IDVerificationSelect v-model="form.idVerification.documentType" :error-container="errorContainerName"
                          name="idVerification.documentType"/>

    <div class="row">
      <div :class="`col-md-${form.idVerification.documentType === 'passport' ? 6 : 4}`">
        <FormGroup v-if="form.idVerification.documentType === 'passport'" :error-container="errorContainerName"
                   label="Passport Number" name="idVerification.passport.number">
          <input v-model="form.idVerification.passport.number"
                 class="form-control" name="documentNumber"
                 type="text">
        </FormGroup>
        <FormGroup v-if="form.idVerification.documentType === 'license'" :error-container="errorContainerName"
                   label="Licence Number" name="idVerification.license.number">
          <input v-model="form.idVerification.license.number"

                 class="form-control" name="documentNumber" type="text">
        </FormGroup>
      </div>
      <div :class="`col-md-${form.idVerification.documentType === 'passport' ? 6 : 4}`">
        <CountrySelect v-if="form.idVerification.documentType === 'passport'"
                       v-model="form.idVerification.passport.country" :error-container="errorContainerName"
                       name="idVerification.passport.country"/>
        <StateSelect v-if="form.idVerification.documentType === 'license'" v-model="form.idVerification.license.state"
                     :error-container="errorContainerName" name="idVerification.license.state"/>
      </div>
      <div v-if="form.idVerification.documentType === 'license'" class="col-md-4">
        <FormGroup
          :error-container="errorContainerName"
          name="idVerification.license.cardNumber"
        >
          <template #default="{ id }">
            <label
              :for="id"
              class="d-flex"
              style="align-items: center; justify-content: flex-start; gap: 0.5rem;"
            >
              <span>Licence Card Number</span> <HelpIcon :selected-state="form.idVerification.license.state"/>
            </label>
            <input v-model="form.idVerification.license.cardNumber" :id="id" class="form-control" name="licenseCardNumber"
                   type="text">
          </template>
        </FormGroup>
      </div>
    </div>

    <Checkbox v-model="form.agreeIdCheck" :error-container="errorContainerName" name="agreeIdCheck">
      <template v-slot="{ onClick, style }">
        <div :style="style" @click="onClick">I agree to have my identity information verified with the Issuer or
          Official Record Holder.
        </div>
      </template>
    </Checkbox>

    <slot name="controls" v-bind="{ prev: onPrev, next: onNext }"/>
  </div>
</template>

<script>
import FormGroup from '@modules/shared/components/form/FormGroup';
import RadioButtonGroup from '@modules/shared/components/form/RadioButtonGroup';
import {NEW_APPLICATION} from '@store/constants';
import NewApplicationStepMixin from '@modules/guest/new-applicant/components/mixins/NewApplicationStepMixin';
import {validate} from './formValidator';
import GenderSelect from '@modules/shared/components/user/GenderSelect';
import IDVerificationSelect from '@modules/shared/components/user/IDVerificationSelect';
import StateSelect from '@modules/shared/components/user/StateSelect';
import CountrySelect from '@modules/shared/components/user/CountrySelect';
import Checkbox from '@modules/guest/new-applicant/components/form/Checkbox';
import {scrollToErrorFormField} from '@modules/shared/helpers/form';
import store from '@modules/guest/new-applicant/store/store';
import {saveAsDraft} from '@modules/guest/new-applicant/NewApplicationService';
import {mergeDeep} from '@modules/shared/helpers/object-fns';
import ServerErrorMixin from '@modules/shared/mixins/ServerErrorMixin';
import {MaritalStatus, ResidentialStatus} from '@modules/guest/new-applicant/constants';
import PlacesAutocomplete from '@modules/shared/components/google/AddressAutocomplete';
import AddressInput from '@modules/guest/new-applicant/components/steps/MoreAboutYourself/AddressInput';
import SmoothReflow from '@modules/shared/transitions/SmoothReflow';
import Modal from '@modules/shared/components/modal/Modal';
import ModalBody from '@modules/shared/components/modal/ModalBody';
import ModalHeader from '@modules/shared/components/modal/ModalHeader';
import dayjs from 'dayjs';
import {StatesOfAustralia} from '@modules/guest/bought-lead/constants';

const ERROR_CONTAINER_NAME = 'NewApplicationMoreAboutYourselfStepForm';

const defaultAddress = () => ({
  unit_number: null,
  street_number: null,
  street_name: null,
  suburb: null,
  state: null,
  country: null,
  postcode: null,
  years: null,
  months: null,
});

const defaultState = () => ({
  firstName: '',
  middleName: '',
  lastName: '',
  gender: '',
  dob: '',
  maritalStatus: '',
  numberOfDependents: '',
  residentialStatus: '',
  australianResident: null,
  addresses: {
    current: defaultAddress(),
    previous: defaultAddress(),
  },
  medicare: {
    color: 'G',
    reference: '',
    middleName: '',
  },
  idVerification: {
    documentType: '',
    passport: {
      number: '',
      country: 'AUS',
    },
    license: {
      number: '',
      state: '',
      cardNumber: '',
    },
  },
  agreeIdCheck: '',
});

const MaritalStatusSelect = {
  name: 'MaritalStatusSelect',
  props: ['value', 'disabled'],
  mixins: [ServerErrorMixin],
  model: {
    prop: 'value',
    event: 'input',
  },
  template: `
    <FormGroup label="Marital Status" :error-container="errorContainerName" name="maritalStatus">
    <select :value="value" @input="$emit('input', $event.target.value)" class="form-control" name="maritalStatus"
            :disabled="disabled">
      <option v-for="(name, idx) in options" :key="idx" :value="idx" v-text="name"/>
    </select>
    </FormGroup>
  `,
  components: {FormGroup},
  computed: {
    options: () => MaritalStatus,
  },
  created() {
    this.registerErrorContainer(ERROR_CONTAINER_NAME);
  },
};

const ResidentialStatusSelect = {
  name: 'ResidentialStatusSelect',
  props: ['value', 'disabled'],
  mixins: [ServerErrorMixin],
  model: {
    prop: 'value',
    event: 'input',
  },
  template: `
    <FormGroup label="Residential Status" :error-container="errorContainerName" name="residentialStatus">
    <select :value="value" @input="$emit('input', $event.target.value)" class="form-control" name="residentialStatus"
            :disabled="disabled">
      <option v-for="(name, idx) in options" :key="idx" :value="idx" v-text="name"/>
    </select>
    </FormGroup>
  `,
  components: {FormGroup},
  computed: {
    options: () => ResidentialStatus,
  },
  created() {
    this.registerErrorContainer(ERROR_CONTAINER_NAME);
  },
};

const MONTH_YEARS = (() => {
  const TODAY = dayjs();

  const CURRENT_YEAR = TODAY.year();
  const MAX_YEARS = CURRENT_YEAR + 10;
  const CURRENT_MONTH = TODAY.month();

  let monthYears = [];

  for (let year = CURRENT_YEAR; year <= MAX_YEARS; year++) {
    for (let month = 0; month <= 11; month++) {

      if (year === CURRENT_YEAR && month < CURRENT_MONTH) {
        continue;
      }

      const current = dayjs().startOf('month').year(year).month(month).endOf('month');

      const key = current.format('MM/YYYY');
      const value = current.format('YYYY-MM-DD');

      monthYears.push({key, value});
    }
  }

  return monthYears;
})();

const HelpIcon = {
  components: {
    StateSelect,
    Modal,
    ModalBody,
    ModalHeader,
  },
  props: ['selectedState'],
  data() {
    return {
      state: null,
    };
  },
  watch: {
    selectedState: {
      immediate: true,
      handler(value) {
        this.state = value ?? StatesOfAustralia.NSW;
      }
    }
  },
  computed: {
    image() {
      return this.state ? `/images/drivers-licences/${this.state}.webp` : null;
    }
  },
  template: `
  <div>
    <i class="fa fa-question-circle" style="cursor: pointer" @click.prevent="$refs.modal.show"/>

    <Modal ref="modal" :options="{ backdrop: true, keyboard: true }" centered>
      <ModalHeader :dark="false" title="Driver's Licence Help" default-header/>

      <ModalBody class="p-2">
        <StateSelect v-model="state"/>
        <div class="d-flex align-items-center justify-content-center p-1" style="border: 1px solid #ced4da; border-radius: 3px;">
          <img :src="image" class="p-0 licence-sample-image" alt="Licence Sample">
        </div>
      </ModalBody>
    </Modal>
  </div>
  `
};


export default {
  name: 'MoreAboutYourself',
  mixins: [NewApplicationStepMixin],
  components: {
    SmoothReflow,
    AddressInput,
    PlacesAutocomplete,
    RadioButtonGroup,
    FormGroup,
    GenderSelect,
    IDVerificationSelect,
    StateSelect,
    CountrySelect,
    Checkbox,
    MaritalStatusSelect,
    ResidentialStatusSelect,
    HelpIcon,
  },
  data() {
    const original = store.state[NEW_APPLICATION.MODULE_NAME].cache ?? {};

    return {
      form: mergeDeep(
        defaultState(),
        original.meta?.MoreAboutYourself ?? {},
        {
          agreeIdCheck: null,
        },
      ),
      meta: {
        showPreviousAddressForm: false,
      },
    };
  },
  computed: {
    code() {
      return store.state[NEW_APPLICATION.MODULE_NAME].code;
    },
    isPreviousAddressRequired() {
      return Object.keys(this.validationErrors).some(key => key.startsWith('addresses.previous')) ||
        Number(this.form.addresses.current.years) < 1;
    },
    today() {
      return dayjs().format('YYYY-MM-DD');
    },
    medicareExpiryOptions: () => MONTH_YEARS,
    numberOfDependentsOptions: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler() {
        this.shouldGoNextStep();
        this.syncWithStore();
      },
    },
  },
  methods: {
    validate,
    onNext() {
      if (!this.shouldGoNextStep()) {
        scrollToErrorFormField(this.$el);
        return;
      }

      this.saveAndSync().then(async () => {
        await store.dispatch(NEW_APPLICATION.ACTIONS.NEXT_STEP);
      }).catch(e => {
        this.handleServerError(e);
        scrollToErrorFormField(this.$el);
      });
    },
    onPrev() {
      store.dispatch(NEW_APPLICATION.ACTIONS.PREV_STEP);
    },
    async saveAndSync() {
      const payload = {
        step: 'MoreAboutYourself',
        ...this.form,
      };

      store.commit({type: NEW_APPLICATION.MUTATIONS.SET_STEPS_META, payload: {loading: true}});

      try {
        const {data: {draft, otp, loanNumber, canPerformCS}} = await saveAsDraft(this.code, payload);
        store.commit({type: NEW_APPLICATION.MUTATIONS.SET_META, payload: {otp, loanNumber, canPerformCS}});
        store.commit({type: NEW_APPLICATION.MUTATIONS.SET_CACHE, payload: draft});
      } catch (e) {
        throw e;
      } finally {
        store.commit({type: NEW_APPLICATION.MUTATIONS.SET_STEPS_META, payload: {loading: false}});
      }
    },
    syncWithStore() {
      const payload = {
        ...this.form,
      };

      this.$store.commit({
        type: NEW_APPLICATION.MUTATIONS.SET_MORE_ABOUT_YOURSELF,
        payload,
      });
    },
    onPreviousAddressRemove() {
      this.meta.showPreviousAddressForm = false;
      this.form.addresses.previous = defaultAddress();
    },
  },
  created() {
    this.registerErrorContainer(ERROR_CONTAINER_NAME);
  },
};
</script>

<style>
.fs-body {
  font-size: 0.85rem;
}

.licence-sample-image {
  object-position: center;
  object-fit: contain;
  width: 100%;
  filter: hue-rotate(180deg) saturate(20%);
}
</style>
