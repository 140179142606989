<template>
  <div v-if="!closed && visible" :class="{callout: true, [`callout--${kind}`]: true}">
    <div class="callout__body">
      <slot/>
    </div>
    <svg v-if="dismissible" class="callout__close" fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" @click.prevent.stop="onCloseRequest">
      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Callout',
  emits: ['close'],
  props: {
    kind: {
      type: String,
      default: 'default',
      validate(value) {
        return ['primary', 'danger', 'info', 'warning'].includes(value);
      },
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      closed: false,
    };
  },
  methods: {
    onCloseRequest() {
      this.closed = true;
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.callout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  gap: 1rem;

  &__body {
    color: #fff;
  }

  &--default {
    background: #d6d6d6;

    .callout__body {
      color: #000000;
    }
  }

  &--info {
    background: #17a2b8;
  }

  &--warning {
    background: #ffca27;
  }

  &--primary {
    background: #6200ee;
  }

  &--danger {
    background: #dc3545;
  }

  &__body {
    font-weight: 500;
  }

  &__close {
    min-width: 1.25rem;
    max-width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    border: 1px solid rgba(0,0,0,0.75);
    padding: 3px;
    color: rgba(0,0,0,0.75);

    &:hover {
      color: #000;
    }
  }
}
</style>
