<template>
  <FormGroup :error-container="errorContainer" :flex="flex" :name="name">
    <template v-slot="{id}">
      <div ref="checkBox" class="check-box" tabindex="0" @keydown.space="onProbableClick"
           @keydown.enter="onProbableClick">
        <input :id="id" ref="input" v-model="proxyValue" :disabled="disabled" style="display: none" type="checkbox">
        <svg fill="currentColor" height="16"
             style="color: #3e3052; background: #ffffff;" viewBox="0 0 16 16" width="16"
             xmlns="http://www.w3.org/2000/svg" @click="() => $refs.input.click()">
          <path :d="svgPath"/>
        </svg>

        <slot v-bind="{ onClick: () => $refs.input.click(), style: {cursor: 'pointer'}}">
          <span style="cursor: pointer; line-height: 1" @click="$refs.input.click()" v-text="label"/>
        </slot>
      </div>
    </template>
  </FormGroup>
</template>

<script>
import FormGroup from '@modules/shared/components/form/FormGroup';

const SVG_PATH = {
  checked: 'M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z',
  unchecked: 'M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z',
};

export default {
  name: 'Checkbox',
  components: {FormGroup},
  props: {
    value: {
      required: false,
      default: false,
    },
    errorContainer: {
      type: String,
      required: false,
      default: null,
    },
    flex: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      required: false,
      default: () => [],
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    svgPath() {
      return this.value ? SVG_PATH.checked : SVG_PATH.unchecked;
    },
    proxyValue: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: {
    onProbableClick(event) {
      if (document.activeElement !== this.$refs.checkBox) {
        return;
      }

      event?.preventDefault();
      event?.stopPropagation();

      this.$refs.input.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.check-box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 0.5rem;
  transition: all 300ms linear;
  border-radius: 2px;

  svg {
    min-width: 1rem;
    cursor: pointer;
    transform: translateY(0.3rem);
    transition: box-shadow 200ms linear;
  }

  &:focus, &:hover {
    svg {
      box-shadow: 0px 0px 5px #666;
    }
  }
}
</style>
