import {LoanAmountRange, LoanPurposes} from '@modules/guest/new-applicant/constants';
import {inList, isBetween, isEmpty, isNumber} from '@modules/shared/helpers/form';
const LoanPurposeDescriptionList = LoanPurposes.map(({description}) => description);

const LoanFrequencies = {
  Weekly: 'weekly',
  Fortnightly: 'fortnightly',
  Monthly: 'monthly',
};

export const validateAmount = ({amount}) => {
  if (isEmpty(amount)) {
    return 'Amount field is required';
  }

  if (!isNumber(amount) || !isBetween(Number(amount), LoanAmountRange.MIN, LoanAmountRange.MAX)) {
    return 'Amount field must be a number between 200 to 5000 inclusive';
  }

  return null;
};

const validateFrequency = ({frequency}) => {
  if (isEmpty(frequency) || !inList(frequency, Object.values(LoanFrequencies))) {
    return 'Frequency field is required';
  }

  return null;
};

const validateDuration = ({terms}) => { // terms
  if (isEmpty(terms) || !isNumber(terms) || !isBetween(Number(terms), 1, 24)) {
    return 'Duration field is required';
  }

  return null;
};

const validateInabilityToRepay = ({inabilityToRepay: foresee}) => {
  if (isEmpty(foresee) || !inList(foresee, ['yes', 'no'])) {
    return 'Please select a valid value.';
  }

  return null;
};

const validateAgreeLoanAmountCanChange = ({agreeLoanAmountCanChange}) => {
  if (agreeLoanAmountCanChange !== true) {
    return 'You must accept the term to continue.';
  }

  return null;
};

const validateInabilityToRepayReason = ({inabilityToRepay: inability, inabilityToRepayReason: reason}) => {
  if (inability === 'yes' && isEmpty(reason)) {
    return 'Please provide a reason.';
  }

  return null;
};

const validateAcknowledgementAndConsentGranted = ({acknowledgementAndConsentGranted: grant}) => {
  if (isEmpty(grant) || grant === false) {
    return 'Acknowledgement and Consent is required.';
  }

  return null;
};

const validatePurpose = ({purpose: {description, reason}}) => {
  return {
    'purpose.description': !inList(description, LoanPurposeDescriptionList) ? 'Loan Purpose field is required.' : null,
    'purpose.reason': isEmpty(reason) ? 'Loan purpose description field is required.' : null,
  };
};

export const validate = (formFields) => {
  return {
    amount: validateAmount(formFields),
    terms: validateDuration(formFields),
    frequency: validateFrequency(formFields),
    ...validatePurpose(formFields),
    agreeLoanAmountCanChange: validateAgreeLoanAmountCanChange(formFields),
    inabilityToRepay: validateInabilityToRepay(formFields),
    inabilityToRepayReason: validateInabilityToRepayReason(formFields),
    acknowledgementAndConsentGranted: validateAcknowledgementAndConsentGranted(formFields),
  };
};

