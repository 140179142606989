import {LoanPurposes} from '@modules/guest/bought-lead/constants';
import Countries from '@modules/shared/constants/countries';
/**
 * Lead Details
 * @typedef {{ Application: { Reason: ?string, Description: ?string, Amount: ?number, Repayment_Cycle: Frequency },
 *   Applicant: {Person_Applicant: { Title: ?string, First_Name: ?string, Middle_Names: ?string, Last_Name: ?string,
 *   Gender: ?string, Date_Of_Birth: ?string, Contact_Methods: { Contact_Method: Array }}, Financials: { Financial:
 *   Financial[] }, Employment_History: { Employment: Array } }, Proof_Of_Identity:
 *   { Identification: Array } }} BoughtLead
 * @typedef {{ Summary_Income: SummaryIncome, Summary_Liability: SummaryLiability }} Financials
 * @typedef {{ Amount: number, Frequency: Frequency }} SummaryLiability
 * @typedef {{ Net_Amount: number, Frequency: Frequency }} SummaryIncome
 * @typedef {('weekly'|'fortnightly'|'monthly')} Frequency
 * @typedef {{amount: number, terms: number, purpose: {reason: string, medical: {practitioner: string, contact:
 *   string,
 *   procedure: string}, description: (string|*)}, inabilityToRepayReason: string,
 *   inabilityToRepay: null, acknowledgementAndConsentGranted: boolean, frequency: Frequency}} LoanDetails
 * @typedef {{lastName: (string|string), idVerification: {license: {number: string, state: string}, passport: {number:
 *   string, country: string}, documentType: (string), medicare: {reference: string, number: string, color: string,
 *   middleName: string, expiry: string, issueDate: string}}, addresses: {current: {address: string, months: string,
 *   years: string}, other: {address: string, months: string, years: string}}, homePhoneNumber: string, gender:
 *   (string|string), mobileNumber: string, agreeIdCheck: string, title: (string|string), firstName: (string|string),
 *   password: string, dob: (string|string), australianResident: string, confirmPassword: string, middleName:
 *   (string|string), email: string}} MoreAboutYourself
 * @typedef {{income: string, otherIncome: string, incomeIncomeFrequency: string, livingExpenseFrequency: string,
 *   houseExpenseFrequency: string, livingExpense: string, employer: string, employmentStatus: string, incomeFrequency:
 *   string, houseExpense: string}} EmploymentDetails
 *  @typedef {{bsb, numberOfDependents: (*|string), accountName, residentialStatus: string, accountNumber,
 *   maritalStatus: (*|string)}} FinancialCapacity
 * @typedef {{ original: BoughtLead | {}, form: { LoanDetails: LoanDetails, MoreAboutYourself: MoreAboutYourself,
 *   EmploymentDetails: EmploymentDetails,  } }} BoughtLeadState
 */

/**
 *
 * @type {BoughtLeadState}
 */
const state = {
  original: {},
  form: {
    LoanDetails: {
      amount: 500,
      frequency: 'weekly',
      terms: 1,
      purpose: {
        description: '',
        medical: {
          procedure: '',
          practitioner: '',
          contact: '',
        },
        reason: '',
      },
      inabilityToRepay: null,
      inabilityToRepayReason: '',
      acknowledgementAndConsentGranted: false,
    },
    MoreAboutYourself: {
      title: '',
      firstName: '',
      middleName: '',
      lastName: '',
      gender: '',
      dob: '',
      email: '',
      mobileNumber: '',
      homePhoneNumber: '',
      password: '',
      confirmPassword: '',
      australianResident: 'yes',
      addresses: {
        current: {
          address: '',
          years: '',
          months: '',
        },
        other: {
          address: '',
          years: '',
          months: '',
        },
      },
      idVerification: {
        documentType: '',
        passport: {
          number: '',
          country: '',
        },
        license: {
          number: '',
          state: '',
        },
        medicare: {
          number: '',
          issueDate: '',
          expiry: '',
          color: 'B',
          reference: '',
          middleName: '',
        },
      },
      agreeIdCheck: '',
    },
    EmploymentDetails: {
      employmentStatus: '',
      employer: '',
      income: '',
      incomeFrequency: '',
      otherIncome: '',
      incomeIncomeFrequency: '',
      houseExpense: '',
      houseExpenseFrequency: '',
      livingExpense: '',
      livingExpenseFrequency: '',
    },
    FinancialCapacity: {
      maritalStatus: '',
      numberOfDependents: '',
      residentialStatus: '',
      accountName: '',
      bsb: '',
      accountNumber: '',
    },
    Review: {
      declareAccurate: false,
      subscribeOffers: false,
      agreeTermsAndConditions: false,
      legal: false,
      jurisdiction: false,
      privacyPolicy: false,
      acknowledgementAndConsent: false,
    },
  },
};

export const MUTATIONS = {
  INIT_LEAD: 'INIT_LEAD',
  SET_LOAN_DETAILS: 'SET_LOAN_DETAILS',
  SET_MORE_ABOUT_YOURSELF: 'SET_MORE_ABOUT_YOURSELF',
  SET_EMPLOYMENT_DETAILS: 'SET_EMPLOYMENT_DETAILS',
  SET_FINANCIAL_CAPACITY: 'SET_FINANCIAL_CAPACITY',
  SET_REVIEW: 'SET_REVIEW',
};

export const GETTERS = {
  LOAN_DETAILS: 'LOAN_DETAILS',
  MORE_ABOUT_YOURSELF: 'MORE_ABOUT_YOURSELF',
  EMPLOYMENT_DETAILS: 'EMPLOYMENT_DETAILS',
  FINANCIAL_CAPACITY: 'FINANCIAL_CAPACITY',
  REVIEW: 'REVIEW',
};

export const ACTIONS = {
  INIT_LEAD: 'INIT_LEAD',
};

export const MODULE_NAME = 'BOUGHT_LEAD';

/**
 *
 * @param lead {BoughtLead}
 * @returns {LoanDetails}
 */
const transformLoanDetails = (lead) => {
  const amount = +lead?.Application?.Amount;
  const frequency = (lead?.Application?.Repayment_Cycle || 'weekly').toLowerCase();
  const reason = [lead.Application?.Reason, lead.Application?.Description].filter(t => !!t).join(': ');
  const purposeDescription = LoanPurposes[LoanPurposes.length - 1].description;

  return {
    amount: (typeof amount === 'number' && isFinite(amount) && amount >= 500) ? amount : 500,
    frequency: frequency,
    terms: 1,
    purpose: {
      description: purposeDescription,
      medical: {
        procedure: '',
        practitioner: '',
        contact: '',
      },
      reason: reason,
    },
    inabilityToRepay: null,
    inabilityToRepayReason: '',
    acknowledgementAndConsentGranted: false,
  };
};

/**
 *
 * @param lead { BoughtLead }
 * @returns {MoreAboutYourself}
 */
const transformMoreAboutYourself = (lead) => {
  const person = lead?.Applicant?.Person_Applicant;
  const contacts = person?.Contact_Methods?.Contact_Method || [];
  const proofOfIdentity = lead?.Applicant?.Proof_Of_Identity?.Identification || [];

  let email = '';
  let mobile = '';
  let currentAddress = '';
  let previousAddress = '';

  contacts.forEach(contact => {
    const {Contact_Other, Address} = contact || {};
    const {V_Contact_Type, V_Contact_Value} = Contact_Other || {};
    const {Address_Line_1, Address_Line_2, Is_Current, Postcode, State, Suburb} = Address || {};

    if (!mobile && V_Contact_Type === 'MOB') {
      mobile = V_Contact_Value;
      return;
    }
    if (!email && V_Contact_Type === 'EMAIL') {
      email = V_Contact_Value;
      return;
    }

    const address = `${Address_Line_1 || ''} ${Address_Line_2 || ''}, ${Suburb} ${State} ${Postcode}`;

    if (!currentAddress && Is_Current) {
      currentAddress = address;
      return;
    }

    if (!previousAddress && !Is_Current) {
      previousAddress = address;
    }
  });

  let passport = {number: '', issueDate: '', expiry: '', country: ''};
  let medicare = {number: '', issueDate: '', expiry: ''};
  let license = {number: '', issueDate: '', expiry: '', state: ''};

  proofOfIdentity.forEach(proof => {
    const {Date_Expiry: expiry, Date_Issue: issueDate, Document_ID: number, Issuer, Type} = proof;

    if (Type === 'Medicare card') {
      medicare.number = number || '';
      medicare.issueDate = issueDate || '';
      medicare.expiry = expiry || '';
      return;
    }
    if (Type === 'Drivers License') {
      license.number = number || '';
      license.issueDate = issueDate || '';
      license.expiry = expiry || '';
      license.state = Issuer || '';
      return;
    }
    if (Type === 'Passport') {
      passport.number = number || '';
      passport.issueDate = issueDate || '';
      passport.expiry = expiry || '';
      passport.country = Issuer || '';
    }
  });

  passport.country = (typeof passport.country !== 'string' ? `${passport.country}` : passport.country).toUpperCase();
  passport.country = Countries[passport.country] ? passport.country : 'AUS';

  return {
    title: person?.Title || '',
    firstName: person?.First_Name || '',
    middleName: person?.Middle_Names || '',
    lastName: person?.Last_Name || '',
    gender: person?.Gender || '',
    dob: person?.Date_Of_Birth || '',
    email: email || '',
    mobileNumber: mobile || '',
    homePhoneNumber: '',
    password: '',
    confirmPassword: '',
    australianResident: 'yes',
    addresses: {
      current: {
        address: currentAddress,
        years: '',
        months: '',
      },
      other: {
        address: previousAddress,
        years: '',
        months: '',
      },
    },
    idVerification: {
      documentType: passport.number ? 'passport' : (license.number ? 'license' : ''),
      passport: {
        number: passport.number || '',
        country: passport.country,
      },
      license: {
        number: license.number || '',
        state: license.state || '',
      },
      medicare: {
        ...medicare,
        color: 'B',
        reference: '',
        middleName: '',
      },
    },
    agreeIdCheck: '',
  };
};

/**
 *
 * @param lead { BoughtLead }
 * @returns {EmploymentDetails}
 */
const transformEmploymentDetails = (lead) => {
  const employment = {
    status: '',
    employer: '',
  };

  (lead.Applicant?.Employment_History?.Employment || []).forEach(({Is_Current, Employment_Type, Organisation_Name}) => {
    if (Is_Current) {
      employment.status = Employment_Type;
      employment.employer = Organisation_Name;
    }
  });

  const {Net_Amount: income, Frequency: incomeFrequency} = ((lead.Applicant?.Financials?.Financial ||
    [])[0])?.Summary_Income || {};
  const {Amount: livingExpense, Frequency: livingExpenseFrequency} = ((lead.Applicant?.Financials?.Financial ||
    [])[0])?.Summary_Liability || {};

  return {
    employmentStatus: employment.status,
    employer: employment.employer,
    income: income || '',
    incomeFrequency: incomeFrequency || '',
    otherIncome: '',
    incomeIncomeFrequency: '',
    houseExpense: '',
    houseExpenseFrequency: '',
    livingExpense: livingExpense || '',
    livingExpenseFrequency: livingExpenseFrequency || '',
  };
};

/**
 *
 * @param lead
 * @returns {FinancialCapacity}
 */
const transformFinancialCapacity = (lead) => {
  const dependents = lead.Applicant?.Person_Applicant?.Dependents || '';
  const maritalStatus = lead.Applicant?.Person_Applicant?.Marital_Status || '';

  const {AccountNumber: Account_Number, AccountBSB: BSB, AccountHolder: Account_Name} = ((lead.Bank_Accounts ??
    [])[0] ?? {});

  return {
    maritalStatus,
    numberOfDependents: dependents,
    residentialStatus: '',
    accountName: Account_Name,
    bsb: BSB,
    accountNumber: Account_Number,
  };
};

export default {
  namespaced: true,
  state: () => state,
  getters: {
    [GETTERS.LOAN_DETAILS]: ({original}) => transformLoanDetails(original),
    [GETTERS.MORE_ABOUT_YOURSELF]: ({original}) => transformMoreAboutYourself(original),
    [GETTERS.EMPLOYMENT_DETAILS]: ({original}) => transformEmploymentDetails(original),
    [GETTERS.FINANCIAL_CAPACITY]: ({original}) => transformFinancialCapacity(original),
    [GETTERS.REVIEW]({form: {LoanDetails, MoreAboutYourself, FinancialCapacity}}) {
      return {
        loanDetails: {
          amount: LoanDetails.amount,
          terms: LoanDetails.terms,
          type: LoanDetails.amount > 2000 ? 'MACC' : 'SACC',
          repaymentAmount: 0,
          frequency: LoanDetails.frequency,
        },
        userDetails: {
          fullName: [
            MoreAboutYourself.title,
            MoreAboutYourself.firstName,
            MoreAboutYourself.middleName,
            MoreAboutYourself.lastName].filter(f => !!f).join(' '),
          dob: MoreAboutYourself.dob,
          email: MoreAboutYourself.email,
          mobile: MoreAboutYourself.mobileNumber,
          address: MoreAboutYourself.addresses.current.address,
        },
        fundDistribution: {
          name: FinancialCapacity.accountName,
          bsb: FinancialCapacity.bsb,
          number: FinancialCapacity.accountNumber,
        },
        idVerification: {
          documentType: MoreAboutYourself.idVerification.documentType,
          passport: {
            number: MoreAboutYourself.idVerification.passport.number,
            country: Countries[MoreAboutYourself.idVerification.passport.country],
          },
          license: MoreAboutYourself.idVerification.license,
          medicare: MoreAboutYourself.idVerification.medicare,
        },
      }
    },
  },
  mutations: {
    [MUTATIONS.INIT_LEAD](state, {payload}) {
      state.original = payload;
    },
    [MUTATIONS.SET_LOAN_DETAILS](state, {payload}) {
      state.form.LoanDetails = payload;
    },
    [MUTATIONS.SET_MORE_ABOUT_YOURSELF](state, {payload}) {
      state.form.MoreAboutYourself = payload;
    },
    [MUTATIONS.SET_EMPLOYMENT_DETAILS](state, {payload}) {
      state.form.EmploymentDetails = payload;
    },
    [MUTATIONS.SET_FINANCIAL_CAPACITY](state, {payload}) {
      state.form.FinancialCapacity = payload;
    },
    [MUTATIONS.SET_REVIEW](state, {payload}) {
      state.form.Review = payload;
    },
  },
  actions: {
    [ACTIONS.INIT_LEAD]({commit}, {payload}) {
      commit({type: MUTATIONS.INIT_LEAD, payload});
      commit({type: MUTATIONS.SET_LOAN_DETAILS, payload: transformLoanDetails(payload)});
      commit({type: MUTATIONS.SET_MORE_ABOUT_YOURSELF, payload: transformMoreAboutYourself(payload)});
      commit({type: MUTATIONS.SET_EMPLOYMENT_DETAILS, payload: transformEmploymentDetails(payload)});
      commit({type: MUTATIONS.SET_FINANCIAL_CAPACITY, payload: transformFinancialCapacity(payload)});
    },
  },
};
