import {validateRequired, validateRequiredInteger} from '@modules/shared/helpers/form';

export const validate = (formFields) => {
  return {
    accountName: validateRequired('Account name', formFields.accountName),
    bsb: validateRequiredInteger('BSB', formFields.bsb),
    accountNumber: validateRequiredInteger('Account number', formFields.accountNumber),
  };
};

