<template>
  <div class="contact-verification-step">
    <div class="d-flex flex-column justify-content-center align-content-center mb-3">
      <EmailVerification ref="emailVerification" :error-container="errorContainerName"/>
      <PhoneVerification ref="phoneVerification" :error-container="errorContainerName"/>
    </div>

    <slot name="controls" v-bind="{ prev: onPrev, next: onNext }" v-if="canGoNext"/>
  </div>
</template>

<script>

import NewApplicationStepMixin from '@modules/guest/new-applicant/components/mixins/NewApplicationStepMixin';
import FormGroup from '@modules/shared/components/form/FormGroup';
import EmailVerification from './components/EmailVerification';
import store from '@modules/guest/new-applicant/store/store';
import {NEW_APPLICATION} from '@store/constants';
import PhoneVerification
  from '@modules/guest/new-applicant/components/steps/ContactVerification/components/PhoneVerification';

const ERROR_CONTAINER_NAME = 'NewApplicationContactVerificationStepForm';

export default {
  name: 'ContactVerification',
  components: {PhoneVerification, EmailVerification, FormGroup},
  mixins: [NewApplicationStepMixin],
  methods: {
    canGoNext() {
      return this.$refs.emailVerification.original.isEmailVerified
        && !this.$refs.emailVerification.meta.requestChange
        && this.$refs.phoneVerification.original.isPhoneVerified
        && !this.$refs.phoneVerification.meta.requestChange;
    },
    onNext() {
      if (!this.canGoNext()) {
        return;
      }

      store.dispatch(NEW_APPLICATION.ACTIONS.NEXT_STEP);
    },
    onPrev() {
      store.dispatch(NEW_APPLICATION.ACTIONS.PREV_STEP);
    },
  },
  mounted() {
  },
  created() {
    this.registerErrorContainer(ERROR_CONTAINER_NAME);
  },
};
</script>

<style scoped>

</style>
