export const EmploymentStatus = {
  'Part Time': 'Part Time',
  'Full Time': 'Full Time',
  'Casual': 'Casual',
  'Contract': 'Contract',
  'Self Employed': 'Self Employed',
  'Government Benefits': 'Government Benefits',
};

export const Frequencies = {
  weekly: 'Weekly',
  fortnightly: 'Fortnightly',
  monthly: 'Monthly',
};
