<template>
  <div class="bank-verification-step">
    <div class="header">
      <h2 class="display-4 text-center">First, let's connect to your bank</h2>
      <p class="sub-title text-center">By doing this we'll be able to automatically calculate your income and
        expenses.</p>
    </div>
    <iframe id="creditSenseIFrame" ref="csIframe" class="cs-iframe" src="about:blank">
      iframes are not supported in this browser
    </iframe>
  </div>
</template>

<script>

import NewApplicationStepMixin from '@modules/guest/new-applicant/components/mixins/NewApplicationStepMixin';
import {scrollToErrorFormField} from '@modules/shared/helpers/form';
import store from '@modules/guest/new-applicant/store/store';
import {NEW_APPLICATION} from '@store/constants';
import {loadScript} from '@modules/shared/helpers/script-loader';
import {debug, IS_DEBUG_MODE, IS_PRODUCTION} from '@/utils';
import {sendBankVerification} from '@modules/guest/new-applicant/NewApplicationService';

const ERROR_CONTAINER_NAME = 'NewApplicationBankVerificationStepForm';
const CS_SCRIPT_URI = 'https://6dadc58e31982fd9f0be-d4a1ccb0c1936ef2a5b7f304db75b8a4.ssl.cf4.rackcdn.com/CS-Integrated-Iframe-v1.min.js';

export default {
  name: 'BankVerification',
  mixins: [NewApplicationStepMixin],
  computed: {
    code() {
      return store.state[NEW_APPLICATION.MODULE_NAME].code;
    },
    loanNumber() {
      return store.state[NEW_APPLICATION.MODULE_NAME].meta.loanNumber;
    },
    canPerformCS() {
      return store.state[NEW_APPLICATION.MODULE_NAME].meta.canPerformCS;
    },
    shouldInitialize() {
      return typeof this.loanNumber === 'string' && this.canPerformCS;
    },
  },
  data() {
    return {
      initialized: false,
    };
  },
  watch: {
    shouldInitialize: {
      immediate: true,
      handler(value) {
        if (!value) {
          return;
        }

        this.initCS();
      },
    },
  },
  methods: {
    onNext() {
      if (!this.shouldGoNextStep()) {
        scrollToErrorFormField(this.$el);
        return;
      }

      store.dispatch(NEW_APPLICATION.ACTIONS.NEXT_STEP);
    },
    onPrev() {
      store.dispatch(NEW_APPLICATION.ACTIONS.PREV_STEP);
    },
    async initCS() {
      if (this.initialized || !this.loanNumber) {
        return;
      }

      await loadScript(CS_SCRIPT_URI);

      this.initialized = true;

      let store = 'FLMY02';
      let debugBanks = IS_DEBUG_MODE;

      if (IS_PRODUCTION) {
        store = 'FLMY01';
      }

      // creditsense - generated application id
      let appId = null;

      $.CreditSense.Iframe({
        client: store,
        elementSelector: '#creditSenseIFrame',
        enableDynamicHeight: false,
        params: {
          appRef: this.loanNumber,
          uniqueAppRef: true,
          debugBanks,
        },
        callback: (response, data) => {
          switch (response) {
            case '3': // status code (Bank status success)
              debug('Application id generated');
              appId = data;
              break;
            case '99': // status code (Bank status success)
              debug('Bank details collected successfully');
              break;
            case '100': // application success
              this.applicationCompleted(appId);
              break;
          }
        },
      });
    },
    async applicationCompleted(appId) {
      try {
        await sendBankVerification(this.code, {appId});
      } catch {
      } finally {
        await store.dispatch(NEW_APPLICATION.ACTIONS.NEXT_STEP);
      }
    },
  },
  created() {
    this.registerErrorContainer(ERROR_CONTAINER_NAME);
  },
};
</script>

<style scoped>
.cs-iframe {
  height: 40rem;
  width: 100%;
  border: 1px solid #ccc;
}
</style>
