<template>
  <component :is="tag">
    <slot v-if="show"/>
  </component>
</template>

<script>
import SmoothTransitionMixin from '@modules/shared/mixins/SmoothTransitionMixin';

export default {
  name: 'SmoothReflow',
  mixins: [SmoothTransitionMixin],
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    options: Object,
    show: {
      type: Boolean,
      default: true,
    }
  },
  mounted () {
    this.$smoothReflow(this.options)
  }
}
</script>
