<template>
  <div v-if="loading" :style="style" class="loader"/>
</template>

<script>
export default {
  name: 'SpinKit',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#ffffff',
    },
  },
  computed: {
    style() {
      return `--color: ${this.color}`;
    },
  },
};
</script>

<style scoped>
.loader {
  --color: #fff;

  width: 1rem;
  height: 1rem;
  border: 2px solid var(--color, #ffffff);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
