<template>
  <Portal v-if="!reload" to="modal-container">
    <div ref="modal" class="modal fade" role="dialog" tabindex="-1">
      <div :class="{ 'modal-lg': large && !extraLarge, 'modal-xl':  extraLarge, 'modal-dialog-centered': centered }" class="modal-dialog modal-dialog-scrollable"
           role="document">
        <div class="modal-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </Portal>
</template>

<script>
import ModalHeader from '@modules/shared/components/modal/ModalHeader.vue';
import ModalFooter from '@modules/shared/components/modal/ModalFooter.vue';

const DEFAULT_OPTIONS = () => ({
  backdrop: 'static',
  keyboard: false,
  focus: true,
});

export default {
  name: 'Modal',
  components: {ModalFooter, ModalHeader},
  emits: ['show', 'onModalClosed'],
  props: {
    options: {
      type: Object,
      required: false,
      default: DEFAULT_OPTIONS,
    },
    large: {
      type: Boolean,
      default: false,
    },
    extraLarge: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'dark',
      validate(value) {
        return ['dark', 'light'].includes(value);
      }
    },
    preventClose: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({reload: false}),
  computed: {
    modalOptions() {
      return {
        ...DEFAULT_OPTIONS(), ...this.options,
      };
    },
  },
  methods: {
    hide() {
      $(this.$refs.modal).modal('hide');
    },
    show() {
      $(this.$refs.modal).modal({...this.modalOptions, show: true});
      this.$emit('show');
    },
    reset() {
      $(this.$refs.modal).modal({...this.modalOptions, show: false});

      this.reload = true;

      setTimeout(() => this.reload = false, 1);
    },
  },
  mounted() {
    $(() => {
      $(this.$refs.modal).on('hidden.bs.modal', () => this.$emit('onModalClosed'));
      $(this.$refs.modal).on('hide.bs.modal', (event) => {
        if(this.preventClose) {
          event.preventDefault(event);
        }
      });
    });
  },
};
</script>

<style scoped lang="scss">
.modal-content {
  border: none;
  border-radius: 0;
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
</style>
