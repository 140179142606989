import {
  Genders,
  IDVerificationDocumentTypes,
  MaritalStatus,
  MedicareCardColors,
  ResidentialStatus,
  StatesOfAustralia,
} from '@modules/guest/bought-lead/constants';
import {
  inObject,
  isAlphaNumeric,
  isBetween,
  isEmpty,
  isNumber,
  msgRequired,
  validateInList,
  validateInObject,
  validateRequired,
  validateRequiredDate,
  validateRequiredInteger,
} from '@modules/shared/helpers/form';
import Countries from '@modules/shared/constants/countries';
import dayjs from 'dayjs';

export const validateMobileNumber = ({mobileNumber}) => {
  if (isEmpty(mobileNumber)) {
    return msgRequired('Mobile number');
  }

  if (
    !(
      /^\d+$/.test(mobileNumber.trim()) &&
      mobileNumber.trim().length === 10 &&
      mobileNumber.trim().startsWith('04')
    )
  ) {
    return 'Mobile number must start with 04 and followed by 8 digits.';
  }

  return null;
};

const validatePassport = ({number, country}) => {
  return {
    'idVerification.passport.number': (() => {
      let error = validateRequired('Passport number', number);

      if (error) {
        return error;
      }

      if (!isAlphaNumeric(number)) {
        return 'Passport number is invalid.';
      }

      return null;
    })(),
    'idVerification.passport.country': (() => {
      let error = validateRequired('Passport country', country);

      if (error) {
        return error;
      }

      if (!inObject(country, Countries)) {
        return 'The selected passport country is invalid.';
      }

      return null;
    })(),
  };
};

const validateLicense = ({number, state, cardNumber}) => {
  const STATES_REQUIRING_LICENSE_CARD_NUMBER = [
    StatesOfAustralia.NSW,
    StatesOfAustralia.SA,
    StatesOfAustralia.ACT,
    StatesOfAustralia.TAS,
    StatesOfAustralia.WA,
    StatesOfAustralia.NT,
    StatesOfAustralia.QLD,
    StatesOfAustralia.VIC,
  ];

  return {
    'idVerification.license.number': (() => {
      let error = validateRequired('Licence number', number);

      if (error) {
        return error;
      }

      if (!isAlphaNumeric(number)) {
        return 'Licence number is invalid.';
      }

      return null;
    })(),
    'idVerification.license.state': validateInObject('Licence state', state, StatesOfAustralia),
    'idVerification.license.cardNumber': (() => {
      if (STATES_REQUIRING_LICENSE_CARD_NUMBER.includes(state) && isEmpty(cardNumber)) {
          return msgRequired('Licence card number');
      }

      if (!isEmpty(number) && !isEmpty(cardNumber) && String(number).trim() === String(cardNumber).trim()) {
          return 'Licence card number cannot be same as your licence number.';
      }

      return null;
    })(),
  };
};

const validateAddresses = ({addresses: {current, previous}}) => {
  const requiredNumber = (fieldName, value) => {
    if (isEmpty(value)) {
      return msgRequired(fieldName);
    } else if (!isNumber(value)) {
      return `${fieldName} field must be a number`;
    }

    return null;
  };

  const validateMonth = ({years, months}) => {
    let error = requiredNumber('Number of months', months);

    if (error) {
      return error;
    }

    if (Number(years) === 0 && !isBetween(months, 1, 11)) {
      return 'Number of months is required and must be between 1 to 11 inclusive.';
    } else if (Number(years) > 0 && !isBetween(months, 0, 11)) {
      return 'Number of months is required and must be between 0 to 11 inclusive.';
    }

    return null;
  };

  const errors = {
    'addresses.current.street_number': isEmpty(current?.street_number) ? msgRequired('Street number') : null,
    'addresses.current.street_name': isEmpty(current?.street_name) ? msgRequired('Street name') : null,
    'addresses.current.suburb': isEmpty(current?.suburb) ? msgRequired('Suburb') : null,
    'addresses.current.state': isEmpty(current?.state) ? msgRequired('State') : null,
    'addresses.current.postcode': isEmpty(current?.postcode) ? msgRequired('Postcode') : null,
    'addresses.current.country': isEmpty(current?.country) ? msgRequired('Country') : null,
    'addresses.current.years': requiredNumber('Number of years', current?.years),
    'addresses.current.months': validateMonth({years: current?.years, months: current?.months}),
  };

  if (current?.years !== null && isNumber(current?.years) && current.years < 1) {
    return {
      ...errors,
      'addresses.previous.street_number': isEmpty(previous?.street_number) ? msgRequired('Street number') : null,
      'addresses.previous.street_name': isEmpty(previous?.street_name) ? msgRequired('Street name') : null,
      'addresses.previous.suburb': isEmpty(previous?.suburb) ? msgRequired('Suburb') : null,
      'addresses.previous.state': isEmpty(previous?.state) ? msgRequired('State') : null,
      'addresses.previous.postcode': isEmpty(previous?.postcode) ? msgRequired('Postcode') : null,
      'addresses.previous.country': isEmpty(previous?.country) ? msgRequired('Country') : null,
    };
  }

  return errors;
};

const validateIdVerification = ({australianResident, idVerification: {documentType, passport, license}, medicare}) => {
  let errors = {
    'australianResident': !australianResident
      ? 'You must be an Australian citizen or a Permanent Resident of Australia'
      : null,
    'idVerification.documentType': validateInObject('Document type', documentType, IDVerificationDocumentTypes),
    'idVerification.passport.number': null,
    'idVerification.passport.country': null,
    'idVerification.license.number': null,
    'idVerification.license.state': null,
    'idVerification.license.cardNumber': null,
    'medicare.number': validateRequired('Medicare card number', medicare.number) ?? (
      /\d{10}/.test(medicare.number.trim()) === false ? 'Medicare card number must be 10 digits long.' : null
    ),
    'medicare.expiry': (() => {
      let error = validateRequiredDate('Medicare card expiry', medicare.expiry);

      if (error) {
        return error;
      }

      const today = dayjs();
      const expiry = dayjs(medicare.expiry);

      if (expiry.year() < today.year() || (expiry.year() === today.year() && expiry.month() < today.month())) {
        return 'Your medicare card is expired.';
      }

      return null;
    })(),
    'medicare.color': validateInObject('Medicare card color', medicare.color, MedicareCardColors),
    'medicare.reference': !isBetween(medicare.reference, 1, 9)
      ? 'Medicare card reference number must be between 1 to 9 inclusive.'
      : null,
  };

  if (errors['idVerification.documentType']) {
    return errors;
  }

  if (IDVerificationDocumentTypes[documentType] === IDVerificationDocumentTypes.passport) {
    errors = {
      ...errors,
      ...validatePassport(passport ?? {}),
    };

    return errors;
  }

  return {
    ...errors,
    ...validateLicense(license ?? {}),
  };
};

const validateDateOfBirth = ({dob}) => {
  let error = validateRequiredDate('Date of birth', dob);

  if (error) {
    return error;
  }

  if (dayjs().diff(dayjs(dob), 'year') < 18) {
    return 'You must be 18 years or older.';
  }

  return null;
};

export const validate = (formFields) => {
  return {
    firstName: validateRequired('First name', formFields.firstName),
    lastName: validateRequired('Last name', formFields.lastName),
    gender: validateInList('Gender', formFields.gender, Object.keys(Genders)),
    dob: validateDateOfBirth(formFields),
    ...validateAddresses(formFields),
    ...validateIdVerification(formFields),
    agreeIdCheck: formFields.agreeIdCheck !== true ? 'This field is required.' : null,
    maritalStatus: validateInObject('Marital status', formFields.maritalStatus, MaritalStatus),
    numberOfDependents: validateRequiredInteger('Number of dependents', formFields.numberOfDependents),
    residentialStatus: validateInObject('Residential status', formFields.residentialStatus, ResidentialStatus),
  };
};

