import axios from 'axios';

const csrf = document.head.querySelector('meta[name=csrf-token]')?.content;

const headers = {
  'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json',
};

if (csrf) {
  headers['X-CSRF-TOKEN'] = csrf;
}

const OTP_TYPES = {
  CONTACT_VERIFICATION: 'CV000000', ACCESS_VERIFICATION: 'AV000000',
};

export const fetchDraft = ({code}) => {
  return axios.get(`/new-application/${code}/show`, {headers});
};
export const saveAsDraft = (code, payload) => {
  return axios.post(`/new-application/${code}`, payload, {headers});
};
export const sendBankVerification = (code, payload = {}) => {
  return axios.put(`/new-application/${code}/bank-verification`, payload, {headers});
};

export const sendAccessOTPCode = (code, payload) => {
  return axios.post(`/new-application/${code}/otp/${OTP_TYPES.ACCESS_VERIFICATION}/send`, payload, {headers});
};
export const verifyAccessOTPCode = (code, payload) => {
  return axios.post(`/new-application/${code}/otp/${OTP_TYPES.ACCESS_VERIFICATION}/verify`, payload, {headers});
};
export const sendContactChangeVerificationOTPCode = (code, payload) => {
  return axios.post(`/new-application/${code}/otp/${OTP_TYPES.CONTACT_VERIFICATION}/send`, payload, {headers});
};
export const verifyContactChangeVerificationOTPCode = (code, payload) => {
  return axios.post(`/new-application/${code}/otp/${OTP_TYPES.CONTACT_VERIFICATION}/verify`, payload, {headers});
};
