<template>
  <div class="countdown-text">
    Did not receive the code?
    <span v-if="count > 0">Wait <strong>{{ count }} {{ count > 1 ? 'seconds' : 'second' }}</strong> to resend.</span>
    <span v-else>
      <button class="btn btn-xs btn-primary" :disabled="count > 0 || disabled" @click="resend">Resend</button>

      or

      <button class="btn btn-xs btn-primary" :disabled="count > 0 || disabled" @click="$emit('requestChange')" v-text="requestChangeText"/>
    </span>
  </div>
</template>

<script>
export default {
  name: 'TimeCountdown',
  props: {
    from: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    requestChangeText: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      count: 0,
      interval: null,
    };
  },
  methods: {
    beginCountdown() {
      clearInterval(this.interval);
      this.count = this.from;

      this.interval = setInterval(() => {
        this.count--;

        if (this.count <= 0) {
          this.count = 0;
          clearInterval(this.interval);
          this.$emit('timeout');
        }
      }, 1000);
    },
    resend() {
      this.beginCountdown();
      this.$emit('resend');
    },
  },
  mounted() {
    this.beginCountdown();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
};
</script>

<style scoped>
.countdown-text {
  font-size: 0.85rem;
}
</style>
