export const validateChecked = (fieldName, value) => {
  if (value !== true) {
    return `${fieldName} is required.`;
  }

  return null;
};

export const validate = ({
  acknowledgementAndConsent,
  agreeTermsAndConditions,
  declareAccurate,
  jurisdiction,
  legal,
  privacyPolicy,
}) => {
  return {
    acknowledgementAndConsent: validateChecked('This', acknowledgementAndConsent),
    agreeTermsAndConditions: validateChecked('This', agreeTermsAndConditions),
    declareAccurate: validateChecked('This', declareAccurate),
    jurisdiction: validateChecked('This', jurisdiction),
    legal: validateChecked('This', legal),
    privacyPolicy: validateChecked('This', privacyPolicy),
  };
};

