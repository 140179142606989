import {HTTP_UNPROCESSABLE_ENTITY} from '@constants/ResponseStatus';
import {error} from '@services/AlertService';
import {ERROR_CONTAINER} from '@store/constants';
import {debug} from '@/utils';
import axios from 'axios';

export default {
  data: () => ({errorContainerName: null}),
  computed: {
    validationErrors() {
      return this.$store.getters[ERROR_CONTAINER.GETTERS.CONTAINER](this.errorContainerName);
    },
    hasValidationErrors() {
      return Object.keys(this.validationErrors).length > 0;
    },
  },
  methods: {
    formErrorClass(key) {
      return {'has-error': this.hasError(key), flex: this.flex};
    },
    hasError(key) {
      return !!this.validationErrors[key];
    },
    getErrorText(key) {
      return this.hasError(key) ? this.validationErrors[key] : null;
    },
    clearServerErrors() {
      this.$store.commit({
        type: ERROR_CONTAINER.MUTATIONS.CLEAR_ERROR_CONTAINER,
        payload: {key: this.errorContainerName},
      });
    },
    setErrorByKey(key, value = null) {
      const errors = {...this.validationErrors};

      errors[key] = value;

      this.setErrors(errors);
    },
    setErrors(errors) {
      this.clearServerErrors();
      this.$store.commit({
        type: ERROR_CONTAINER.MUTATIONS.ADD_ERROR_CONTAINER,
        payload: {key: this.errorContainerName, errors},
      });
    },
    async handleServerError(e) {

      let {data, status} = axios.isAxiosError(e) ? e.response : {};

      // encountered blob as response data
      if (data instanceof Blob && data.type === 'application/json') {
        try {
          data = JSON.parse(await data.text());
        } catch (e) {
          debug(e);
        }
      }

      const serverErrors = data?.errors || {};

      if (status === HTTP_UNPROCESSABLE_ENTITY && Object.keys(serverErrors).length > 0) {
        const errors = {};

        for (const key in serverErrors) {
          errors[key] = Array.isArray(serverErrors[key]) ? serverErrors[key][0] : serverErrors[key];
        }

        this.setErrors(errors);
      } else {
        const message = data?.message || 'Server error encountered';

        // noinspection JSIgnoredPromiseFromCall,ES6MissingAwait
        error({message});
      }
    },
    registerErrorContainer(key) {
      if (!key) {
        return;
      }
      // noinspection JSUnresolvedVariable
      this.$store.dispatch({
        type: ERROR_CONTAINER.ACTIONS.REGISTER,
        payload: {key},
      }).then(() => {
        this.errorContainerName = key;
      });
    },
  },
};
