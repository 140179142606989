<template>
  <div class="lead-loan-application">
    <div class="in-banner"/>

    <div class="container-fluid container--title">
      <h1 class="display-4">Loan Application</h1>
      <p class="lead">We've made the application easier than you think.</p>
    </div>

    <div class="container container--content">
      <Callout :visible="uniqueReminderState" @close="uniqueReminderClosed" dismissible kind="primary" class="callout-unique-info">
        This link is unique to your application. You can bookmark this page and continue later.
      </Callout>

      <div class="card border-0">
        <div class="container--steps">
          <StepProgress :current-step="steps.current" :steps="steps.list"/>
        </div>
        <div class="card-body p-0">
          <div ref="stepHeader" class="step__title p-3 gap-2">
            <span class=" m-0">{{ (steps.current + 1) + '. ' + steps.list[steps.current] }}</span>
          </div>
          <div ref="applicationSteps" class="application-steps">
            <TransitionGroup class="d-block" mode="out-in" name="slide-fade" tag="div">
              <Component :is="name" v-for="(name, idx) in stepViews" v-show="idx === steps.current" :key="name"
                         ref="steps" :loan-number="loanNumber">
                <template v-slot:controls="{ next, prev }">
                  <Portal v-if="idx === steps.current" to="new-loan-application-controls">
                    <button v-if="prev && previousButtonText && !isLocked" :disabled="disableActionButtons"
                            class="btn btn-outline-dark"
                            @click.stop.prevent="prev">
                      <svg fill="currentColor" height="24" viewBox="0 0 16 16" width="24"
                           xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                          fill-rule="evenodd"/>
                      </svg>
                      <span v-text="previousButtonText"/>
                    </button>

                    <button v-if="next && !isLastStep || !isLocked" :disabled="disableActionButtons" class="btn btn-outline-dark"
                            @click.stop.prevent="next">
                      <span>Next</span>
                      <svg fill="currentColor" height="24" viewBox="0 0 16 16" width="24"
                           xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                          fill-rule="evenodd"/>
                      </svg>
                    </button>

                    <a v-if="isLastStep" class="btn btn-outline-dark" href="/login">
                      <span>Login</span>
                      <svg fill="currentColor" height="24" viewBox="0 0 16 16" width="24"
                           xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                          fill-rule="evenodd"/>
                      </svg>
                    </a>
                  </Portal>
                </template>
              </Component>
            </TransitionGroup>
          </div>
        </div>
      </div>

      <div class="card rounded-0 card--steps">
        <PortalTarget :multiple="true" class="card-body steps__navigation" name="new-loan-application-controls"/>
      </div>

      <Portal v-if="steps.meta.loading" to="steps-spinner">
        <div class="steps-loader">
          <Spinner :loading="true" :opacity="0.85" :overlay="true" theme="dark">
            <template v-slot:loading-text>
              <span class="text-lowercase"
                    style="font-size: 0.85rem; text-shadow: rgba(0, 0, 0, 0.85) 1px 1px 3px;"
              >Please wait ...</span>
            </template>
          </Spinner>
        </div>
      </Portal>
    </div>
  </div>
</template>

<script>
import LoanDetails from './steps/LoanDetails/LoanDetails';
import ContactVerification from './steps/ContactVerification/ContactVerification';
import MoreAboutYourself from './steps/MoreAboutYourself/MoreAboutYourself';
import EmploymentDetails from './steps/EmploymentDetails/EmploymentDetails';
import BankingInformation from './steps/BankingInformation/BankingInformation';
import BankVerification from './steps/BankVerification/BankVerification';
import Review from './steps/Review/Review';
import ThankYou from './steps/ThankYou';
import {NEW_APPLICATION} from '@store/constants';
import Spinner from '@components/common/Spinner';
import {scrollToElement} from '@modules/shared/helpers/form';
import StepProgress from '@modules/shared/components/StepProgress';
import store from '@modules/guest/new-applicant/store/store';
import Password from './steps/Password/Password';
import Callout from '@modules/shared/components/display/Callout';

const StepComponentMap = {
  LoanDetails,
  ContactVerification,
  MoreAboutYourself,
  EmploymentDetails,
  BankingInformation,
  Review,
  Password,
  BankVerification,
  ThankYou,
};

const StepNames = [
  'Loan Details',
  'Contact Verification',
  'More About Yourself',
  'Employment Details',
  'Banking Information',
  'Review',
  'Password',
  'Bank Verification',
  'Done',
];

const gtagPayload = (step) => {
  let stepName = StepNames[step];

  if (!stepName) {
    return [null, null];
  }

  const action = (step + 1) + '_' + stepName.toLowerCase().replaceAll(' ', '_');

  return [
    action,
    {
      'event_category': 'draft_loan_application',
      'event_label': `Draft Loan Application`,
    }
  ];
};

const SHOW_UNIQUE_REMINDER = (() => {
  const reminder = window.localStorage.getItem('uniqueReminderState');

  if (reminder === null) {
    return true;
  }

  return Boolean(Number(reminder));
})();

export default {
  name: 'LoanApplication',
  components: {Callout, StepProgress, Spinner, ...StepComponentMap},
  data() {
    return {
      loanNumber: null,
      firstLoad: true,
      gclid: null,
      uniqueReminderState: SHOW_UNIQUE_REMINDER,
    };
  },
  computed: {
    isLocked() {
      return store.getters[NEW_APPLICATION.GETTERS.IS_LOCKED];
    },
    canPerformCS() {
      return store.state[NEW_APPLICATION.MODULE_NAME].meta.canPerformCS;
    },
    steps() {
      return store.state[NEW_APPLICATION.MODULE_NAME].steps;
    },
    step() {
      if (this.isLocked && this.canPerformCS) {
        return 7;
      } else if (this.isLocked) {
        return 8;
      }

      return this.steps.current;
    },
    meta() {
      return store.state[NEW_APPLICATION.MODULE_NAME].meta;
    },
    code() {
      return store.state[NEW_APPLICATION.MODULE_NAME].code;
    },
    stepViews: () => Object.keys(StepComponentMap).map(index => StepComponentMap[index].name),
    nextButtonText() {
      return StepNames[this.steps.current + 1] || 'Login';
    },
    previousButtonText() {
      return StepNames[this.steps.current - 1] || null;
    },
    isLastStep() {
      return this.steps.current === StepNames.length - 1;
    },
    disableActionButtons() {
      return this.steps.meta.loading;
    },
  },
  watch: {
    ['steps.current']: {
      immediate: true,
      handler(current, previous) {
        if (current < this.step) {
          store.commit({type: NEW_APPLICATION.MUTATIONS.SET_CURRENT_STEP, payload: this.step});
          return;
        }

        if (!previous || current > previous) {
          const [action, payload] = gtagPayload(current);

          if (!payload || !action) {
            return;
          }

          this.$gtag(action, payload);
        }

        this.$nextTick(() => {
          setTimeout(() => {
            if (this.firstLoad || current === this.steps.list.length - 1) {
              window.scroll({top: 0});
              this.firstLoad = false;
            } else {
              scrollToElement(this.$refs.stepHeader);
            }
          }, 100);
        });
      },
    },
  },
  methods: {
    uniqueReminderClosed() {
      window.localStorage.setItem('uniqueReminderState', '0');
    }
  },
  created() {
    if (typeof window.gclid !== 'function') {
      return;
    }

    gclid().then((client_id) => {
      this.gclid = client_id;
    });
  }
};
</script>

<style lang="scss">
.lead-loan-application {
  .container--title {
    text-align: center;
    padding: 2rem 0.5rem 1rem;

    .lead {
      margin: 0;
    }

    .display-4 {
      font-size: 2.25rem;
    }
  }

  @media (max-width: 576px) {
    .callout-unique-info {
      border-radius: 0;
      border: 0;
      font-size: 0.8rem;
      margin: 0 2px;
    }

    .container--title {
      padding-top: 1rem;

      .display-4 {
        font-size: 1.15rem;
        font-weight: 500;
      }

      .lead {
        font-size: 1rem;
      }
    }
  }

  .in-banner {
    background: url('/images/banner-application.jpg') center 0 no-repeat #ffca27;
    background-size: cover;
  }

  .card--steps {
    position: sticky;
    bottom: 0;
    background: rgba(134, 134, 134, 0.06);
    border-radius: 0;
    backdrop-filter: blur(5px);
    border: 1px solid #c1c1c1 !important;
    border-width: 1px 0px !important;
  }

  .steps {
    &__navigation {
      display: flex;
      width: 100%;

      button, a {
        display: inline-flex;
        align-items: center;

        &:last-child {
          margin-left: auto;
        }
      }
    }
  }

  .step {
    &__title {
      padding: 1rem;
      margin: 0;
      font-weight: 500;
      border-bottom: 1px solid #e9ecef;
      font-size: 1rem;
      background: #eaedf0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      color: #6200ee;

      @media (max-width: 576px) {
        background: #ffffff;
      }
    }
  }

  .application-steps {
    padding: 2rem 3rem;
    overflow: hidden;
    transition: 250ms ease max-height;

    @media (max-width: 576px) {
      padding: 1.5rem;
    }
  }

  .card {
    &-title {
      background-color: #f2f2f2;
    }
  }

  .container {
    &--steps {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
    }

    &--content {
      padding: 0;
      background: transparent;
      position: relative;
    }

    @media (max-width: 576px) {
      &--steps {
        display: none;
      }
    }
  }

  .col-form-label {
    font-weight: 500;
  }

}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all 0s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}
</style>
