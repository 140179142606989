import {namespaced} from '@/store/uitls';
import * as Profile from '@/store/modules/profile.js';
import * as Application from '@/store/modules/application.js';
import * as ErrorContainer from '@/store/modules/ErrorContainer.js';
import * as PaymentTypes from '@/store/modules/additional-payment/modules/payment-types.js';
import * as AdditionalPayment from '@/store/modules/additional-payment/additional-payment';
import * as GracePeriod from '@store/modules/GracePeriod';
import * as Solicitor from '@store/modules/Solicitor';
import * as LoanState from '@store/modules/application/modules/LoanState';
import * as LoanAlert from '@store/modules/LoanAlert';
import * as BoughtLead from '@modules/guest/bought-lead/store/BoughtLead';
import * as NewApplication from '@modules/guest/new-applicant/store/modules/NewApplicant';
import * as ApprovalRejectionTypes from '@store/modules/application/modules/ApprovalRejectionTypes';

const moduleName = (...modules) => modules.map(({MODULE_NAME}) => MODULE_NAME);

const transform = ({MODULE_NAME, GETTERS, MUTATIONS, ACTIONS}) => ({
  MODULE_NAME,
  GETTERS: namespaced(MODULE_NAME, GETTERS),
  MUTATIONS: namespaced(MODULE_NAME, MUTATIONS),
  ACTIONS: namespaced(MODULE_NAME, ACTIONS),
});

export const APPLICATION = transform(Application);
export const APPROVAL_REJECTION_TYPES = transform(ApprovalRejectionTypes);
export const NEW_APPLICATION = transform(NewApplication);
export const LOAN_STATE = transform({
  ...LoanState,
  MODULE_NAME: moduleName(Application, LoanState),
});
export const ADDITIONAL_PAYMENT = transform(AdditionalPayment);
export const ERROR_CONTAINER = transform(ErrorContainer);
export const GRACE_PERIOD = transform(GracePeriod);
export const PAYMENT_TYPES = transform({
  ...PaymentTypes,
  MODULE_NAME: moduleName(AdditionalPayment, PaymentTypes),
});
export const PROFILE = transform(Profile);
export const SOLICITOR = transform(Solicitor);
export const LOAN_ALERT = transform(LoanAlert);
export const BOUGHT_LEAD = transform(BoughtLead);

export const STORE_MODULE_CONSTANTS = {
  APPLICATION,
  NEW_APPLICATION,
  ADDITIONAL_PAYMENT,
  ERROR_CONTAINER,
  GRACE_PERIOD,
  PAYMENT_TYPES,
  PROFILE,
  SOLICITOR,
  LOAN_ALERT,
  BOUGHT_LEAD,
  APPROVAL_REJECTION_TYPES,
};

export default STORE_MODULE_CONSTANTS;
