import Vue from 'vue';
import Vuex from 'vuex';
import ErrorContainer, {MODULE_NAME as ERROR_CONTAINER_MODULE_NAME} from '@store/modules/ErrorContainer';
import NewApplicant, {MODULE_NAME as NEW_APPLICANT_MODULE_NAME} from './modules/NewApplicant';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    [ERROR_CONTAINER_MODULE_NAME]: ErrorContainer,
    [NEW_APPLICANT_MODULE_NAME]: NewApplicant,
  },
});

export const dispatch = store.dispatch;
export const commit = store.commit;

export default store;
