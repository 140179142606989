<template>
  <div class="lead-loan-application">
    <div class="in-banner"/>

    <div class="bg-transparent mt-4 pb-2 pt-2 text-center">
      <div class="container-fluid">
        <h1 class="display-4">Loan Application</h1>
      </div>
    </div>

    <div class="container container--content">
      <div class="card border-0">
        <div class="card-header">
          <div class="card-title bg-transparent">OTP Verification</div>
        </div>
        <div class="card-body">
          <p class="alert alert-info">
            To continue with your application one-time-passcode (OTP) is required.
          </p>

          <FormGroup label="Choose verification mode">
            <RadioButtonGroup v-model="mode" :block="true" :options="{email: 'Email', phone: 'Phone'}" size="sm"/>
          </FormGroup>

          <EmailVerification v-if="mode === 'email'" @verified="verified"/>
          <PhoneVerification v-else @verified="verified"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormGroup from '@modules/shared/components/form/FormGroup';
import RadioButtonGroup from '@modules/shared/components/form/RadioButtonGroup';
import EmailVerification from './components/EmailVerification';
import PhoneVerification from './components/PhoneVerification';
import store, {dispatch} from '@modules/guest/new-applicant/store/store';
import {NEW_APPLICATION} from '@store/constants';

export default {
  name: 'OTPVerification',
  components: {
    PhoneVerification,
    EmailVerification,
    FormGroup,
    RadioButtonGroup
  },
  computed: {
    code() {
      return store.state[NEW_APPLICATION.MODULE_NAME].code;
    },
  },
  data() {
    return {
      mode: 'email',
    }
  },
  methods: {
    verified() {
      dispatch({ type: NEW_APPLICATION.ACTIONS.SET_CODE, payload: this.code });
    }
  }
};
</script>
